import { useCallback, useEffect, useState } from "react";
import {
  AutoRoleAssignmentRuleAPIErrorType,
  AutoRoleAssignmentRuleType,
} from "../models";
import AutomaticRoleAssignmentRuleService from "../services/AutomaticRoleAssignmentRuleService";
import { enqueueSnackbar } from "notistack";

const BASE_ERROR_MESSAGE = "Error with Automated Assignment Rule: ";
const FETCH_ERROR_MESSAGE = "Something went wrong. Please try again later.";
const CREATE_RECORD_SUCCESS_MESSAGE =
  "Automated Assignment Rule created successfully";
const UPDATE_RECORD_SUCCESS_MESSAGE =
  "Automated Assignment Rule updated successfully";

const useAutomationRules = () => {
  const [automationRules, setAutomationRules] = useState<
    AutoRoleAssignmentRuleType[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchAutomatedRules = useCallback(async () => {
    try {
      setLoading(true);
      const ruleList =
        await AutomaticRoleAssignmentRuleService.getAutomaticRoleAssignmentRules();
      setAutomationRules(ruleList);
    } catch (error) {
      setError(error);
      enqueueSnackbar(FETCH_ERROR_MESSAGE, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, []);

  const createAutomatedRule = useCallback(
    async (rule: AutoRoleAssignmentRuleType): Promise<boolean> => {
      try {
        setLoading(true);
        await AutomaticRoleAssignmentRuleService.createAutomaticRoleAssignmentRule(
          rule
        );
        enqueueSnackbar(CREATE_RECORD_SUCCESS_MESSAGE, { variant: "success" });
        fetchAutomatedRules();
        return true;
      } catch (error) {
        setError(error);
        enqueueSnackbar(
          BASE_ERROR_MESSAGE +
            (error as AutoRoleAssignmentRuleAPIErrorType)?.response?.data
              ?.detail,
          {
            variant: "error",
          }
        );
        setLoading(false);
        return false;
      }
    },
    [fetchAutomatedRules]
  );

  const updateAutomatedRule = useCallback(
    async (rule: AutoRoleAssignmentRuleType): Promise<boolean> => {
      try {
        setLoading(true);
        await AutomaticRoleAssignmentRuleService.updateAutomaticRoleAssignmentRule(
          rule
        );
        enqueueSnackbar(UPDATE_RECORD_SUCCESS_MESSAGE, { variant: "success" });
        fetchAutomatedRules();
        return true;
      } catch (error) {
        setError(error);
        enqueueSnackbar(
          BASE_ERROR_MESSAGE +
            (error as AutoRoleAssignmentRuleAPIErrorType)?.response?.data
              ?.detail,
          {
            variant: "error",
          }
        );
        setLoading(false);
        return false;
      }
    },
    [fetchAutomatedRules]
  );

  useEffect(() => {
    fetchAutomatedRules();
  }, [fetchAutomatedRules]);

  return {
    automationRules,
    loading,
    error,
    refetch: fetchAutomatedRules,
    addRecord: createAutomatedRule,
    updateRecord: updateAutomatedRule,
  };
};

export default useAutomationRules;
