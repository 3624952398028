import { startCase } from "lodash";
import { ADGroupRuleExclusionType } from "../../models";
import { ADGroupRuleExclusionErrorType } from "./interface";

export const validateADGroupRuleExclusionForm = (
  form: ADGroupRuleExclusionType
) => {
  const errors: ADGroupRuleExclusionErrorType = {};

  const requiredFields: (keyof ADGroupRuleExclusionErrorType)[] = [
    "notes",
    "employeeId",
  ];

  requiredFields.forEach((field) => {
    if (!form[field]) {
      errors[field] = `${startCase(field)} is required`;
    }
  });

  return errors;
};

export default validateADGroupRuleExclusionForm;
