import { ADGroupRuleType } from "../../models";
import { ADGroupRuleFormErrorType } from "./interface";
import { startCase } from "lodash";

export const validateADGroupRuleForm = (form: ADGroupRuleType) => {
  const errors: ADGroupRuleFormErrorType = {};

  const requiredFields: (keyof ADGroupRuleFormErrorType)[] = [
    "shortDescription",
    "adGroup",
    "role",
  ];

  requiredFields.forEach((field) => {
    if (!form[field]) {
      errors[field] = `${startCase(field)} is required`;
    }
  });

  return errors;
};

export default validateADGroupRuleForm;
