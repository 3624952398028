import APIService from "../api/APIService";
import { ADGroupType } from "../models";

class EntraADGroupService {
  async getEntraADGroups(groupName?: string): Promise<ADGroupType[]> {
    try {
      const groups: ADGroupType[] = await APIService.getEntraADGroups(
        groupName
      );
      return groups;
    } catch (error) {
      console.error("Error fetching ad groups:", error);
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new EntraADGroupService();
