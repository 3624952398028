import APIService from "../api/APIService";
import { ADGroupType } from "../models";

class ADGroupRegistrationService {
  async getADGroups(): Promise<ADGroupType[]> {
    try {
      const groups: ADGroupType[] = await APIService.getADGroups();
      return groups;
    } catch (error) {
      console.error("Error fetching ad groups:", error);
      throw error;
    }
  }

  async importADGroup(group: ADGroupType): Promise<ADGroupType> {
    try {
      const newGroup: ADGroupType = await APIService.importADGroup(group);
      return newGroup;
    } catch (error) {
      console.error("Error importing ad group:", error);
      throw error;
    }
  }

  async deleteADGroup(group: ADGroupType): Promise<void> {
    try {
      await APIService.deleteADGroup(group);
    } catch (error) {
      console.error("Error deleting ad group:", error);
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ADGroupRegistrationService();
