import { Box, Grid, Skeleton, TextField, Typography } from "@mui/material";
import { Props } from "./interface";
import { TEXT_FIELD_STYLES } from "../../constants";
import { PageviewOutlined as PageViewIcon } from "@mui/icons-material";

const GRID_SIZE_PROPS = { xs: 12, md: 6, lg: 3 };

const SKELETON_HEIGHT = 54;

const AutomationRuleDetailCard = ({ rule, loading }: Props) => (
  <Box
    sx={{
      padding: 2,
      borderRadius: 2,
      border: "1px solid lightgray",
      textAlign: "left",
      marginBottom: 3,
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
      <PageViewIcon sx={{ marginRight: 0.5 }} />
      <Typography variant="h6" component="h4">
        Automated Role Assignment Details
      </Typography>
    </Box>
    <Grid container columnSpacing={2} rowSpacing={3}>
      {loading ? (
        <>
          {Array.from({ length: 4 }).map((_, index) => (
            <Grid item {...GRID_SIZE_PROPS} key={index}>
              <Skeleton variant="rectangular" height={SKELETON_HEIGHT} />
            </Grid>
          ))}
        </>
      ) : (
        <>
          <Grid item {...GRID_SIZE_PROPS}>
            <TextField
              disabled
              sx={TEXT_FIELD_STYLES}
              label="Short Description"
              variant="outlined"
              value={rule?.shortDescription}
            />
          </Grid>

          <Grid item {...GRID_SIZE_PROPS}>
            <TextField
              disabled
              sx={TEXT_FIELD_STYLES}
              label="Job Code ID"
              variant="outlined"
              value={rule?.jobCodeId}
            />
          </Grid>

          <Grid item {...GRID_SIZE_PROPS}>
            <TextField
              disabled
              sx={TEXT_FIELD_STYLES}
              label="Role ID"
              variant="outlined"
              value={rule?.roleId}
            />
          </Grid>

          <Grid item {...GRID_SIZE_PROPS}>
            <TextField
              disabled
              sx={TEXT_FIELD_STYLES}
              label="Organization Level"
              variant="outlined"
              value={rule?.organizationLevel}
            />
          </Grid>
        </>
      )}
    </Grid>
  </Box>
);

export default AutomationRuleDetailCard;
