import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from "axios";
import { getAccessToken, usingAuthentication } from "../auth";
import {
  ADGroupRuleExclusionType,
  ADGroupRuleType,
  ADGroupType,
  AutomaticRoleAssignmentRuleExclusionType,
  AutoRoleAssignmentRuleType,
  EmployeeType,
  JobCodeType,
  OrgHierarchyTabularType,
  RoleAssignmentType,
  RoleCreateType,
  RoleIDType,
  RoleType,
  SimplifiedAssignmentType,
} from "../models";
import {
  convertAPIADGroupExclusionToUIADGroupExclusion,
  convertAPIADGroupRuleToUIADGroupRule,
  convertAPIADGroupToUIADGroup,
  convertAPIAutomationRuleToUIAutomationRule,
  convertAPIEmployeeToUIEmployee,
  convertAPIJobCodeToUIJobCode,
  convertAPIOrgHierarchyToUIOrgHierarchy,
  convertAPIRoleAssignmentToUIRoleAssignment,
  convertAPIRoleExclusionToUIRoleExclusion,
  convertAPIRoleToUIRole,
  convertCreateAPIRoleToUIRole,
  convertCreateUIRoleToAPIRole,
  convertUIADGroupExclusionToAPIADGroupExclusion,
  convertUIADGroupRuleToAPIADGroupRule,
  convertUIADGroupToAPIADGroup,
  convertUIAutomationRuleToAPIAutomationRule,
  convertUIRoleAssignmentToAPIRoleAssignment,
  convertUIRoleExclusionToAPIRoleExclusion,
  convertUIRoleToAPIRole,
  convertUISimplifiedAssignmentToAPIRoleAssignment,
} from "../utils";
import api_config from "./config";

class APIService {
  private baseURL = api_config.apiBaseURL;

  async getDefaultHeaders(): Promise<RawAxiosRequestHeaders> {
    if (usingAuthentication()) {
      return {
        Authorization: `Bearer ${(await getAccessToken()).token}`,
      };
    }

    return {};
  }

  async getOrgHierarchy(): Promise<OrgHierarchyTabularType[]> {
    try {
      const response = await axios.get<any[]>(
        `${this.baseURL}/organizational-node-fully-enriched`,
        {
          timeout: api_config.apiTimeouts.getOrgHierarchy,
          headers: await this.getDefaultHeaders(),
        }
      );
      return response.data.map(convertAPIOrgHierarchyToUIOrgHierarchy);
    } catch (error) {
      console.error("Error fetching organizational hierarchy:", error);
      throw error;
    }
  }

  async getRoles(): Promise<RoleType[]> {
    try {
      const response = await axios.get<any[]>(`${this.baseURL}/roles`, {
        timeout: api_config.apiTimeouts.getRoles,
        headers: await this.getDefaultHeaders(),
      });
      return response.data.map(convertAPIRoleToUIRole);
    } catch (error) {
      console.error("Error fetching roles:", error);
      throw error;
    }
  }

  async saveRole(role: RoleType): Promise<RoleType> {
    try {
      const { roleDescriptionWithID, ...updatedRole } = role; //removed roleDescriptionWithID field
      const apiRole = convertUIRoleToAPIRole(updatedRole);
      const response = await axios.put<any>(
        `${this.baseURL}/role/${role.roleID}`,
        apiRole,
        {
          timeout: api_config.apiTimeouts.saveRole,
          headers: await this.getDefaultHeaders(),
        }
      );
      return convertAPIRoleToUIRole(response.data);
    } catch (error) {
      console.error("Error saving role:", error);
      throw error;
    }
  }

  async createRole(role: RoleCreateType): Promise<RoleIDType> {
    try {
      const apiRole = convertCreateUIRoleToAPIRole(role);
      const response = await axios.post<any>(`${this.baseURL}/role`, apiRole, {
        timeout: api_config.apiTimeouts.saveRole,
        headers: await this.getDefaultHeaders(),
      });
      console.log("Response", response.data);
      return convertCreateAPIRoleToUIRole(response.data);
    } catch (error) {
      console.error("Error creating role:", error);
      throw error;
    }
  }

  async deleteRole(roleId: number): Promise<void> {
    try {
      await axios.delete(`${this.baseURL}/role/${roleId}`, {
        timeout: api_config.apiTimeouts.deleteRole,
        headers: await this.getDefaultHeaders(),
      });
    } catch (error) {
      console.error(`Error deleting role with ID: ${roleId}`, error);
      throw error;
    }
  }

  async getEmployees(): Promise<EmployeeType[]> {
    try {
      const response = await axios.get<any[]>(`${this.baseURL}/employees`, {
        timeout: api_config.apiTimeouts.getEmployees,
        headers: await this.getDefaultHeaders(),
      });
      return response.data.map(convertAPIEmployeeToUIEmployee);
    } catch (error) {
      console.error("Error fetching employees:", error);
      throw error;
    }
  }

  async getRoleAssignments(
    employeeId: string,
    roleId: string,
    includePermitted = false
  ): Promise<RoleAssignmentType[]> {
    if (!employeeId.trim() || !roleId.trim()) {
      throw new Error(
        "Invalid role assignment query: both employeeId and roleId must be provided and not blank"
      );
    }

    const url = `${this.baseURL}/role-assignments`;
    const params = {
      employee_id: employeeId,
      role_id: roleId,
      include_permitted: includePermitted,
    };

    const axiosConfig: AxiosRequestConfig = {
      url: url,
      method: "get",
      params: params,
      timeout: api_config.apiTimeouts.getRoleAssignments,
      headers: await this.getDefaultHeaders(),
    };

    // Manually construct and log the full URL
    const fullUrl = `${url}?${new URLSearchParams(params as any).toString()}`;
    console.log("Getting role assignments URL: ", fullUrl);

    try {
      const response = await axios.request<any[]>(axiosConfig);
      const filteredList = response.data.filter(
        (apiRoleAssignment) =>
          apiRoleAssignment.assignment_status === "assigned"
      );
      const convertedList = filteredList.map(
        convertAPIRoleAssignmentToUIRoleAssignment
      );
      // console.log("Finally the converted list: ", convertedList);
      return convertedList;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        console.warn(
          "Role assignments not found (404). Returning empty array."
        );
        return [];
      } else {
        console.error("Error fetching role assignments:", error);
        throw error;
      }
    }
  }

  async addRoleAssignment(
    roleAssignment: RoleAssignmentType
  ): Promise<RoleAssignmentType> {
    const apiRoleAssignment =
      convertUIRoleAssignmentToAPIRoleAssignment(roleAssignment);
    // console.log("saving this new role assignment ", roleAssignment);
    // console.log("JSON payload: ", JSON.stringify(apiRoleAssignment, null, 2)); // Pretty-print the JSON for easier reading

    try {
      const response = await axios.post<any>(
        `${this.baseURL}/role-assignment`,
        apiRoleAssignment,
        {
          timeout: api_config.apiTimeouts.addRoleAssignment,
          headers: await this.getDefaultHeaders(),
        }
      );
      return convertAPIRoleAssignmentToUIRoleAssignment(response.data);
    } catch (error) {
      console.error("Error adding role assignment:", error);
      throw error;
    }
  }

  async deleteRoleAssignment(roleAssignmentId: number): Promise<void> {
    try {
      await axios.delete(
        `${this.baseURL}/role-assignment/${roleAssignmentId}`,
        {
          timeout: api_config.apiTimeouts.deleteRoleAssignment,
          headers: await this.getDefaultHeaders(),
        }
      );
    } catch (error) {
      console.error(
        `Error deleting role assignment with ID: ${roleAssignmentId}`,
        error
      );
      throw error;
    }
  }

  async getJobCodes(): Promise<JobCodeType[]> {
    try {
      const response = await axios.get<any[]>(`${this.baseURL}/job-codes`, {
        timeout: api_config.apiTimeouts.getJobCodes,
        headers: await this.getDefaultHeaders(),
      });
      return response.data.map(convertAPIJobCodeToUIJobCode);
    } catch (error) {
      console.error("Error fetching job codes:", error);
      throw error;
    }
  }

  async getAutomaticRoleAssignmentRules(): Promise<
    AutoRoleAssignmentRuleType[]
  > {
    try {
      const response = await axios.get<any[]>(
        `${this.baseURL}/automatic-role-assignment-rules`,
        {
          timeout: api_config.apiTimeouts.getAutomaticRoleAssignmentRules,
          headers: await this.getDefaultHeaders(),
        }
      );
      return response.data.map(convertAPIAutomationRuleToUIAutomationRule);
      // return Promise.resolve([]);
    } catch (error) {
      console.error("Error fetching automatic role assignment rules:", error);
      throw error;
    }
  }

  async createAutomaticRoleAssingmentRule(
    automaticRule: AutoRoleAssignmentRuleType
  ): Promise<AutoRoleAssignmentRuleType> {
    const apiAutomaticRule =
      convertUIAutomationRuleToAPIAutomationRule(automaticRule);
    try {
      const response = await axios.post<any>(
        `${this.baseURL}/automatic-role-assignment-rule`,
        apiAutomaticRule,
        {
          timeout: api_config.apiTimeouts.addAutomaticRoleAssignmentRule,
          headers: await this.getDefaultHeaders(),
        }
      );
      return { id: response.data };
    } catch (error) {
      console.error("Error adding automatic role assignment rule:", error);
      throw error;
    }
  }

  async updateAutomaticRoleAssignmentRule(
    automaticRule: AutoRoleAssignmentRuleType
  ): Promise<AutoRoleAssignmentRuleType> {
    const apiAutomaticRule =
      convertUIAutomationRuleToAPIAutomationRule(automaticRule);
    try {
      const response = await axios.put<any>(
        `${this.baseURL}/automatic-role-assignment-rule/${automaticRule.id}`,
        apiAutomaticRule,
        {
          timeout: api_config.apiTimeouts.saveAutomaticRoleAssignmentRule,
          headers: await this.getDefaultHeaders(),
        }
      );
      return convertAPIAutomationRuleToUIAutomationRule(response.data);
    } catch (error) {
      console.error("Error adding automatic role assignment rule:", error);
      throw error;
    }
  }

  async getAutomaticRoleAssignmentRuleDetail(
    ruleId: string
  ): Promise<AutoRoleAssignmentRuleType> {
    try {
      const response = await axios.get<any>(
        `${this.baseURL}/automatic-role-assignment-rule/${ruleId}`,
        {
          timeout: api_config.apiTimeouts.getAutomaticRoleAssignmentRuleDetail,
          headers: await this.getDefaultHeaders(),
        }
      );
      return convertAPIAutomationRuleToUIAutomationRule(response.data);
    } catch (error) {
      console.error(
        "Error fetching automatic role assignment rule details:",
        error
      );
      throw error;
    }
  }

  async getEntraADGroups(groupName?: string): Promise<ADGroupType[]> {
    try {
      const response = await axios.get<any[]>(
        `${this.baseURL}/ad-groups-from-entra`,
        {
          timeout: api_config.apiTimeouts.getEntraADGroups,
          headers: await this.getDefaultHeaders(),
          params: { group_name: groupName },
        }
      );
      return response.data.map(convertAPIADGroupToUIADGroup);
    } catch (error) {
      console.error("Error fetching ad groups from Entra:", error);
      throw error;
    }
  }

  async getADGroups(): Promise<ADGroupType[]> {
    try {
      const response = await axios.get<any[]>(
        `${this.baseURL}/ad-group-registration`,
        {
          timeout: api_config.apiTimeouts.getEntraADGroups,
          headers: await this.getDefaultHeaders(),
        }
      );
      return response.data.map(convertAPIADGroupToUIADGroup);
    } catch (error) {
      console.error("Error fetching ad groups:", error);
      throw error;
    }
  }

  async importADGroup(uiADGroup: ADGroupType): Promise<ADGroupType> {
    try {
      const response = await axios.post<any>(
        `${this.baseURL}/ad-group-registration`,
        convertUIADGroupToAPIADGroup(uiADGroup),
        {
          timeout: api_config.apiTimeouts.importADGroup,
          headers: await this.getDefaultHeaders(),
        }
      );
      return convertAPIADGroupToUIADGroup(response.data);
    } catch (error) {
      console.error("Error importing ad group:", error);
      throw error;
    }
  }

  async deleteADGroup(uiADGroup: ADGroupType): Promise<void> {
    try {
      await axios.delete(
        `${this.baseURL}/ad-group-registration/${uiADGroup.adGroupRegistrationId}`,
        {
          timeout: api_config.apiTimeouts.deleteADGroup,
          headers: await this.getDefaultHeaders(),
        }
      );
    } catch (error) {
      console.error("Error deleting ad group:", error);
      throw error;
    }
  }

  async getADGroupRules(): Promise<ADGroupRuleType[]> {
    try {
      const response = await axios.get<any[]>(
        `${this.baseURL}/auto-ad-group-assignment-rules`,
        {
          timeout: api_config.apiTimeouts.getADGroupRules,
          headers: await this.getDefaultHeaders(),
        }
      );
      return response.data.map(convertAPIADGroupRuleToUIADGroupRule);
    } catch (error) {
      console.error("Error fetching ad group rules:", error);
      throw error;
    }
  }

  async createADGroupRule(
    uiADGroupRule: ADGroupRuleType
  ): Promise<ADGroupRuleType> {
    try {
      const response = await axios.post<any>(
        `${this.baseURL}/auto-ad-group-assignment-rules`,
        convertUIADGroupRuleToAPIADGroupRule(uiADGroupRule),
        {
          timeout: api_config.apiTimeouts.createADGroupRule,
          headers: await this.getDefaultHeaders(),
        }
      );
      return convertAPIADGroupRuleToUIADGroupRule(response.data);
    } catch (error) {
      console.error("Error creating ad group rule:", error);
      throw error;
    }
  }

  async updateADGroupRule(
    uiADGroupRule: ADGroupRuleType
  ): Promise<ADGroupRuleType> {
    try {
      const response = await axios.put<any>(
        `${this.baseURL}/auto-ad-group-assignment-rules/${uiADGroupRule.autoAdGroupAssignmentRulesId}`,
        convertUIADGroupRuleToAPIADGroupRule(uiADGroupRule),
        {
          timeout: api_config.apiTimeouts.updateADGroupRule,
          headers: await this.getDefaultHeaders(),
        }
      );
      return convertAPIADGroupRuleToUIADGroupRule(response.data);
    } catch (error) {
      console.error("Error updating ad group rule:", error);
      throw error;
    }
  }

  async getADGroupRuleDetail(ruleId: string): Promise<ADGroupRuleType> {
    try {
      const response = await axios.get<any>(
        `${this.baseURL}/auto-ad-group-assignment-rules/${ruleId}`,
        {
          timeout: api_config.apiTimeouts.getADGroupRuleDetail,
          headers: await this.getDefaultHeaders(),
        }
      );
      return convertAPIADGroupRuleToUIADGroupRule(response.data);
    } catch (error) {
      console.error("Error fetching ad group rule details:", error);
      throw error;
    }
  }

  async getEmployeeRoleAssignments(
    employeeId: string
  ): Promise<RoleAssignmentType[]> {
    try {
      const response = await axios.get<any[]>(
        `${this.baseURL}/role-assignments`,
        {
          timeout: api_config.apiTimeouts.getRoleAssignments,
          headers: await this.getDefaultHeaders(),
          params: { employee_id: employeeId, include_permitted: false },
        }
      );
      return response.data.map(convertAPIRoleAssignmentToUIRoleAssignment);
    } catch (error) {
      console.error("Error fetching employee role assignments:", error);
      throw error;
    }
  }

  async createEmployeeAssignment(
    employeeId: string,
    uiSimplifiedAssignment: SimplifiedAssignmentType
  ): Promise<RoleAssignmentType> {
    try {
      const response = await axios.post<any[]>(
        `${this.baseURL}/role-assignment`,
        convertUISimplifiedAssignmentToAPIRoleAssignment(
          employeeId,
          uiSimplifiedAssignment
        ),
        {
          timeout: api_config.apiTimeouts.addMultipleRoleAssignment,
          headers: await this.getDefaultHeaders(),
        }
      );
      return convertAPIRoleAssignmentToUIRoleAssignment(response.data);
    } catch (error) {
      console.error("Error creating employee role assignment:", error);
      throw error;
    }
  }

  async deleteEmployeeAssignment(assignmentId: string): Promise<void> {
    try {
      await axios.delete(`${this.baseURL}/role-assignment/${assignmentId}`, {
        timeout: api_config.apiTimeouts.deleteRoleAssignment,
        headers: await this.getDefaultHeaders(),
      });
    } catch (error) {
      console.error("Error deleting employee role assignment:", error);
      throw error;
    }
  }

  async getAutomaticRoleAssignmentRuleExclusions(
    ruleId: string
  ): Promise<AutomaticRoleAssignmentRuleExclusionType[]> {
    try {
      const response = await axios.get<any[]>(
        `${this.baseURL}/automatic-role-assignment-rule-employee-exclusions`,
        {
          timeout:
            api_config.apiTimeouts.getAutomaticRoleAssignmentRuleExclusions,
          headers: await this.getDefaultHeaders(),
          params: { rule_id: ruleId },
        }
      );
      return response.data.map(convertAPIRoleExclusionToUIRoleExclusion);
    } catch (error) {
      console.error(
        "Error fetching automatic role assignment rule exclusions:",
        error
      );
      throw error;
    }
  }

  async createAutomaticRoleAssignmentRuleExclusion(
    uiRuleExclusion: AutomaticRoleAssignmentRuleExclusionType
  ): Promise<AutomaticRoleAssignmentRuleExclusionType> {
    try {
      const response = await axios.post<any>(
        `${this.baseURL}/automatic-role-assignment-rule-employee-exclusion`,
        convertUIRoleExclusionToAPIRoleExclusion(uiRuleExclusion),
        {
          timeout:
            api_config.apiTimeouts.createAutomaticRoleAssignmentRuleExclusion,
          headers: await this.getDefaultHeaders(),
        }
      );
      return convertAPIRoleExclusionToUIRoleExclusion(response.data);
    } catch (error) {
      console.error(
        "Error creating automatic role assignment rule exclusion:",
        error
      );
      throw error;
    }
  }

  async deleteAutomaticRoleAssignmentRuleExclusion(
    exclusionId: string
  ): Promise<void> {
    try {
      await axios.delete(
        `${this.baseURL}/automatic-role-assignment-rule-employee-exclusion/${exclusionId}`,
        {
          timeout:
            api_config.apiTimeouts.deleteAutomaticRoleAssignmentRuleExclusion,
          headers: await this.getDefaultHeaders(),
        }
      );
    } catch (error) {
      console.error(
        "Error deleting automatic role assignment rule exclusion:",
        error
      );
      throw error;
    }
  }

  async getADGroupRuleExclusions(
    ruleId: string
  ): Promise<ADGroupRuleExclusionType[]> {
    try {
      const response = await axios.get<any[]>(
        `${this.baseURL}/automatic-ad-group-assignment-rule-employee-exclusion`,
        {
          timeout: api_config.apiTimeouts.getADGroupRuleExclusions,
          headers: await this.getDefaultHeaders(),
          params: { rule_id: ruleId },
        }
      );
      return response.data.map(convertAPIADGroupExclusionToUIADGroupExclusion);
    } catch (error) {
      console.error(
        "Error fetching automatic Entra group membership exclusions:",
        error
      );
      throw error;
    }
  }

  async createADGroupRuleExclusion(
    uiRuleExclusion: ADGroupRuleExclusionType
  ): Promise<ADGroupRuleExclusionType> {
    try {
      const response = await axios.post<any>(
        `${this.baseURL}/automatic-ad-group-assignment-rule-employee-exclusion`,
        convertUIADGroupExclusionToAPIADGroupExclusion(uiRuleExclusion),
        {
          timeout: api_config.apiTimeouts.createADGroupRuleExclusions,
          headers: await this.getDefaultHeaders(),
        }
      );
      return convertAPIADGroupExclusionToUIADGroupExclusion(response.data);
    } catch (error) {
      console.error(
        "Error creating automatic Entra group membership exclusion:",
        error
      );
      throw error;
    }
  }

  async deleteADGroupRuleExclusion(exclusionId: string): Promise<void> {
    try {
      await axios.delete(
        `${this.baseURL}/automatic-ad-group-assignment-rule-employee-exclusion/${exclusionId}`,
        {
          timeout: api_config.apiTimeouts.deleteADGroupRuleExclusions,
          headers: await this.getDefaultHeaders(),
        }
      );
    } catch (error) {
      console.error(
        "Error deleting automatic Entra group membership exclusion:",
        error
      );
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new APIService();
