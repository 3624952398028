import APIService from "../api/APIService";
import { RoleAssignmentType, SimplifiedAssignmentType } from "../models";

class EmployeeAssignmentService {
  async getEmployeeRoleAssignments(
    employeeId: string
  ): Promise<RoleAssignmentType[]> {
    try {
      const assignments: RoleAssignmentType[] =
        await APIService.getEmployeeRoleAssignments(employeeId);
      return assignments;
    } catch (error) {
      console.error("Error fetching employee role assignments:", error);
      throw error;
    }
  }

  async createEmployeeAssignment(
    employeeId: string,
    uiSimplifiedAssignment: SimplifiedAssignmentType
  ): Promise<RoleAssignmentType> {
    try {
      const assignment = await APIService.createEmployeeAssignment(
        employeeId,
        uiSimplifiedAssignment
      );
      return assignment;
    } catch (error) {
      console.error("Error creating employee role assignment:", error);
      throw error;
    }
  }

  async deleteEmployeeAssignment(assignmentId: string): Promise<void> {
    try {
      await APIService.deleteEmployeeAssignment(assignmentId);
    } catch (error) {
      console.error("Error deleting employee role assignment:", error);
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new EmployeeAssignmentService();
