import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { useContext, useState } from "react";
import { SimplifiedRoleAssignmentContext } from "../../context";
import { Delete as DeleteIcon } from "@mui/icons-material";

const EmployeeAssignmentsTable = () => {
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      roleAssignmentID: false,
      roleID: false,
      nodeID: false,
      assignmentType: false,
    });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const {
    orgNodes,
    roles,
    employeeAssignments,
    loadingEmployeeAssignments,
    error,
    deleteAssignment,
  } = useContext(SimplifiedRoleAssignmentContext);

  const ActionsColumn: GridColDef = {
    field: "actions",
    headerName: "Actions",
    type: "actions",
    getActions: (params) => {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Edit"
          onClick={() =>
            deleteAssignment(params.row.roleAssignmentID.toString())
          }
          color="inherit"
        />,
      ];
    },
  };

  const AssignmentTypeColumn: GridColDef = {
    field: "assignmentType",
    headerName: "Assignment Type",
    valueGetter: (_, value) =>
      value.assignmentType === 1 ? "Primary" : "Secondary",
  };

  const RoleNameColumn: GridColDef = {
    field: "roleName",
    headerName: "Role Name",
    valueGetter: (_, value) =>
      roles.find((r) => r.roleID === parseInt(value.roleID))?.roleName,
  };

  const RoleDescriptionColumn: GridColDef = {
    field: "roleDescription",
    headerName: "Role Description",
    width: 150,
    valueGetter: (_, value) =>
      roles.find((r) => r.roleID === parseInt(value.roleID))?.roleDescription,
  };

  const NodeNameColumn: GridColDef = {
    field: "nodeName",
    headerName: "Node Name",
    width: 180,
    valueGetter: (_, value) =>
      orgNodes.find((n) => n.nodeID === parseInt(value.nodeID))?.nodeName,
  };

  const NodeReverseFullyQualifiedDescriptionColumn: GridColDef = {
    field: "nodeReverseFullyQualifiedDescription",
    headerName: "Reverse Fully Qualified Description",
    width: 500,
    valueGetter: (_, value) =>
      orgNodes.find((n) => n.nodeID === parseInt(value.nodeID))
        ?.reverseFullyQualifiedNodeDescription,
  };

  return (
    <Box sx={{ height: "calc(100vh - 236px)", width: "100%", marginTop: 3 }}>
      <DataGridPremium
        loading={loadingEmployeeAssignments}
        rowSelection={false}
        rows={error ? [] : employeeAssignments}
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { quickFilterProps: { debounceMs: 300 } } }}
        autosizeOnMount
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
        getRowId={(row) => row.roleAssignmentID || 0}
        columns={[
          ActionsColumn,
          {
            field: "roleAssignmentID",
            headerName: "ID",
          },
          {
            field: "roleID",
            headerName: "Role ID",
          },
          RoleNameColumn,
          RoleDescriptionColumn,
          {
            field: "nodeID",
            headerName: "Node ID",
          },
          NodeNameColumn,
          NodeReverseFullyQualifiedDescriptionColumn,
          AssignmentTypeColumn,
        ]}
      />
    </Box>
  );
};

export default EmployeeAssignmentsTable;
