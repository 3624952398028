import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { usingAuthentication, WithAuthentication } from "./auth";
import { MUI_LICENSE, ROUTES } from "./constants";
import {
  ADGroupRules,
  ADGroupRulesExclusion,
  ImportADGroups,
  RoleAssignmentManager,
  RoleAssignmentRules,
  RoleManager,
  SimplifiedRoleAssignment,
} from "./pages";
import NavDrawer from "./pages/NavDrawer";
import RoleAssignmentRulesExclusion from "./pages/RoleAssignmentRulesExclusion";
import { RoleAssignmentProvider } from "./RoleAssignmentProvider";

const {
  HOME,
  ROLE_ASSIGNMENT,
  SIMPLIFIED_ROLE_ASSIGNMENT,
  ROLES,
  ROLE_ASSIGNMENT_RULES,
  ROLE_ASSIGNMENT_EXCLUSIONS,
  AD_GROUP_RULES,
  AD_GROUP_EXCLUSIONS,
  IMPORT_AD_GROUPS,
} = ROUTES;

const theme = createTheme({
  palette: {
    primary: {
      main: "#c6002b",
    },
  },
});

LicenseInfo.setLicenseKey(MUI_LICENSE);

const App = () => {
  const content = (
    <RoleAssignmentProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div className="App">
            <Routes>
              <Route element={<NavDrawer />}>
                <Route path={HOME.url} element={<SimplifiedRoleAssignment />} />
                <Route
                  path={SIMPLIFIED_ROLE_ASSIGNMENT.url}
                  element={<SimplifiedRoleAssignment />}
                />
                <Route
                  path={ROLE_ASSIGNMENT.url}
                  element={<RoleAssignmentManager />}
                />
                <Route path={ROLES.url} element={<RoleManager />} />
                <Route path={ROLE_ASSIGNMENT_RULES.url}>
                  <Route index element={<RoleAssignmentRules />} />
                  <Route
                    path={ROLE_ASSIGNMENT_EXCLUSIONS.url}
                    element={<RoleAssignmentRulesExclusion />}
                  />
                </Route>
                <Route path={AD_GROUP_RULES.url}>
                  <Route index element={<ADGroupRules />} />
                  <Route
                    path={AD_GROUP_EXCLUSIONS.url}
                    element={<ADGroupRulesExclusion />}
                  />
                  <Route
                    path={IMPORT_AD_GROUPS.url}
                    element={<ImportADGroups />}
                  />
                </Route>
              </Route>
            </Routes>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </RoleAssignmentProvider>
  );

  return usingAuthentication() ? (
    <WithAuthentication>{content}</WithAuthentication>
  ) : (
    content
  );
};

export default App;
