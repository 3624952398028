import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  HelpOutline as HelpIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ALLOW_AUTO_DELETE_HELPER_TEXT,
  BLANK_ERROR_MESSAGE,
  getDescriptionByValue,
  INACTIVATION_BEHAVIOR_HELPER_TEXT,
  ORGANIZATION_ASSIGNMENT_LEVEL_TABLE,
  TEXT_FIELD_STYLES,
} from "../../constants";
import { useJobCodes, useRoles } from "../../hooks";
import {
  AutoRoleAssignmentRuleType,
  RuleInactivationBehaviorType,
  RuleRoleAssignmentType,
} from "../../models";
import { Props, RuleFormErrorType } from "./interface";
import validateAutomationRuleForm from "./utils";

const EMPTY_FORM_VALUE = {
  shortDescription: "",
  jobCodeId: "",
  roleId: "",
  active: true,
  roleAssignmentType: 3 as RuleRoleAssignmentType,
  inactivationBehavior: "Keep All Assignments" as RuleInactivationBehaviorType,
  deprovisionManualAssignments: false,
  organizationLevel: "",
};

const GRID_SIZE_PROPS = { xs: 12, md: 6, lg: 3 };

const AutomationRuleForm = ({ rule, onCancel, onFormSubmit }: Props) => {
  const [formFields, setFormFields] = useState<AutoRoleAssignmentRuleType>(
    rule || EMPTY_FORM_VALUE
  );
  const [formError, setFormError] = useState<RuleFormErrorType>();

  const { roles, loading: loadingRoles } = useRoles();
  const { jobCodes, loading: loadingJobCodes } = useJobCodes();

  const inEditMode = Boolean(rule);
  const Icon = inEditMode ? EditIcon : AddIcon;

  const onFormChange = (
    key: keyof AutoRoleAssignmentRuleType,
    value: string | boolean | number
  ) => {
    setFormFields((prev) => {
      if (!prev) return { [key]: value };
      return { ...prev, [key]: value };
    });
  };

  const handleFormSubmitClick = () => {
    const errors = validateAutomationRuleForm(formFields);
    if (Object.keys(errors).length) {
      return setFormError(errors);
    }
    onFormSubmit(formFields);
  };

  useEffect(() => {
    if (!rule) {
      return setFormFields(EMPTY_FORM_VALUE);
    }
    setFormFields(rule);
    setFormError({});
  }, [rule]);

  return (
    <Box
      sx={{
        padding: 1,
        borderRadius: 2,
        border: "1px solid lightgray",
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Icon sx={{ marginRight: 0.5 }} />
          <Typography variant="h6" component="h4">
            {inEditMode
              ? `Edit Automatic Role Assignment ${rule?.shortDescription}`
              : "Add Automatic Role Assignment"}
          </Typography>
        </Box>
        <IconButton color="error" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item {...GRID_SIZE_PROPS}>
          <TextField
            required
            sx={TEXT_FIELD_STYLES}
            label="Short Description"
            variant="outlined"
            inputProps={{ maxLength: 25 }}
            value={formFields?.shortDescription}
            onChange={(e) => {
              onFormChange("shortDescription", e.target.value);
            }}
            error={Boolean(formError?.shortDescription)}
            helperText={formError?.shortDescription || BLANK_ERROR_MESSAGE}
          />
        </Grid>

        <Grid item {...GRID_SIZE_PROPS}>
          <Autocomplete
            disabled={inEditMode}
            loading={loadingJobCodes}
            options={jobCodes}
            getOptionLabel={(option) => option.jobCode || ""}
            onChange={(_, value) =>
              onFormChange("jobCodeId", value?.jobCode || "")
            }
            value={
              jobCodes.find((code) => code.jobCode === formFields?.jobCodeId) ||
              null
            }
            filterOptions={(options, params) => {
              return options.filter((option) =>
                option.jobCode.startsWith(params.inputValue)
              );
            }}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Job Code"
                variant="outlined"
                sx={TEXT_FIELD_STYLES}
                error={Boolean(formError?.jobCodeId)}
                helperText={formError?.jobCodeId || BLANK_ERROR_MESSAGE}
              />
            )}
          />
        </Grid>

        <Grid item {...GRID_SIZE_PROPS}>
          <Autocomplete
            disabled={inEditMode}
            loading={loadingRoles}
            options={roles}
            getOptionLabel={(option) => option.roleDescriptionWithID || ""}
            onChange={(_, value) =>
              onFormChange("roleId", value?.roleID.toString() || "")
            }
            value={
              roles.find(
                (role) => role.roleID.toString() === formFields?.roleId
              ) || null
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Role"
                variant="outlined"
                sx={TEXT_FIELD_STYLES}
                error={Boolean(formError?.roleId)}
                helperText={formError?.roleId || BLANK_ERROR_MESSAGE}
              />
            )}
          />
        </Grid>

        <Grid item {...GRID_SIZE_PROPS}>
          <Autocomplete
            loading={loadingRoles}
            options={ORGANIZATION_ASSIGNMENT_LEVEL_TABLE}
            getOptionLabel={(option) => `${option.name} `}
            onChange={(_, option) =>
              onFormChange("organizationLevel", option?.value || "")
            }
            value={
              ORGANIZATION_ASSIGNMENT_LEVEL_TABLE.find(
                (option) => option.value === formFields?.organizationLevel
              ) || null
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Organization Level"
                variant="outlined"
                sx={TEXT_FIELD_STYLES}
                error={
                  Boolean(formError?.organizationLevel) &&
                  !Boolean(formFields?.organizationLevel)
                }
                helperText={
                  getDescriptionByValue(formFields?.organizationLevel) ||
                  formError?.organizationLevel ||
                  BLANK_ERROR_MESSAGE
                }
              />
            )}
          />
        </Grid>

        <Grid item {...GRID_SIZE_PROPS}>
          <FormControl disabled={formFields?.active}>
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <FormLabel>Inactivation Behavior</FormLabel>
              <Tooltip title={INACTIVATION_BEHAVIOR_HELPER_TEXT}>
                <HelpIcon fontSize="small" color="info" />
              </Tooltip>
            </Stack>
            <RadioGroup
              name="inactivation-behavior"
              value={formFields?.inactivationBehavior}
              onChange={(_, value) =>
                onFormChange("inactivationBehavior", value)
              }
            >
              <FormControlLabel
                value="Keep All Assignments"
                control={<Radio />}
                label="Keep All Assignments"
              />
              <FormControlLabel
                value="Deprovision Auto Assignments"
                control={<Radio />}
                label="Deprovision Auto Assignments"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item {...GRID_SIZE_PROPS}>
          <FormGroup>
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <FormLabel>Other Options</FormLabel>
              <Tooltip title={ALLOW_AUTO_DELETE_HELPER_TEXT}>
                <HelpIcon fontSize="small" color="info" />
              </Tooltip>
            </Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Active"
              labelPlacement="end"
              checked={formFields?.active}
              onChange={(_, value) => onFormChange("active", value)}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Allow Auto-Delete Manual Assignments"
              labelPlacement="end"
              checked={formFields?.deprovisionManualAssignments}
              onChange={(_, value) =>
                onFormChange("deprovisionManualAssignments", value)
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 4,
          alignItems: "center",
        }}
      >
        <Button
          size="large"
          variant="contained"
          startIcon={<Icon />}
          onClick={handleFormSubmitClick}
        >
          {inEditMode ? `Save Changes` : "Add New Automation"}
        </Button>
      </Box>
    </Box>
  );
};

export default AutomationRuleForm;
