import {
  Close as CloseIcon,
  SaveAlt as SaveAltIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import debounce from "lodash.debounce";
import { useState } from "react";
import { BLANK_ERROR_MESSAGE, TEXT_FIELD_STYLES } from "../../constants";
import useEntraADGroups from "../../hooks/useEntraADGroups";
import { ADGroupType } from "../../models";
import { ADGroupFormErrorType, Props } from "./interface";
import { validateADGroupForm } from "./utils";

const EMPTY_FORM_VALUE = {
  adGroupRegistrationId: undefined,
  entraObjectId: "",
  groupName: "",
  description: "",
  registrationNotes: "",
};
const DEBOUNCE_TIME = 500;
const GRID_SIZE_PROPS = { xs: 12, md: 6, lg: 6 };

const AD_GROUP_HELPER_TEXT =
  "There are several groups not included in this initial list. Start typing the group name to find the one you are looking for.";

const ADGroupForm = ({ onCancel, onFormSubmit }: Props) => {
  const [formFields, setFormFields] = useState<ADGroupType>(EMPTY_FORM_VALUE);
  const [formError, setFormError] = useState<ADGroupFormErrorType>();
  const [groupName, setGroupName] = useState("");

  const { entraADGroups, loading } = useEntraADGroups(groupName);

  const resetForm = () => {
    setFormFields(EMPTY_FORM_VALUE);
    setFormError(undefined);
  };

  const handleCreateRecord = async () => {
    const success = await onFormSubmit(formFields);
    if (success) {
      return resetForm();
    }
  };

  const handleFormSubmitClick = () => {
    const errors = validateADGroupForm(formFields);
    if (Object.keys(errors).length) {
      return setFormError(errors);
    }
    handleCreateRecord();
  };

  const handleFormGroupChange = (value: ADGroupType | null) => {
    if (Boolean(value)) {
      return setFormFields({ ...formFields, ...value });
    }
    setFormFields(EMPTY_FORM_VALUE);
  };

  const updateGroupNameWithDebounce = debounce(
    (nextValue) => setGroupName(nextValue),
    DEBOUNCE_TIME
  );

  return (
    <Box
      sx={{
        padding: 1,
        borderRadius: 2,
        border: "1px solid lightgray",
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SaveAltIcon sx={{ marginRight: 0.5 }} />
          <Typography variant="h6" component="h4">
            Import Entra Group
          </Typography>
        </Box>
        <IconButton color="error" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item {...GRID_SIZE_PROPS}>
          <Autocomplete
            loading={loading}
            options={entraADGroups}
            getOptionLabel={(option) => option.groupName || ""}
            onChange={(_, value) => handleFormGroupChange(value)}
            value={
              entraADGroups.find(
                (group) => group.entraObjectId === formFields?.entraObjectId
              ) || null
            }
            filterOptions={(x) => x}
            onInputChange={(_e, value) => updateGroupNameWithDebounce(value)}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Not Imported AD Group"
                variant="outlined"
                sx={TEXT_FIELD_STYLES}
                error={Boolean(formError?.entraObjectId)}
                helperText={formError?.entraObjectId || AD_GROUP_HELPER_TEXT}
              />
            )}
          />
        </Grid>

        <Grid item {...GRID_SIZE_PROPS}>
          <TextField
            sx={TEXT_FIELD_STYLES}
            label="Registration Notes"
            variant="outlined"
            inputProps={{ maxLength: 25 }}
            value={formFields?.registrationNotes}
            onChange={(e) => {
              setFormFields({
                ...formFields,
                registrationNotes: e.target.value,
              });
            }}
            helperText={BLANK_ERROR_MESSAGE}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 4,
          alignItems: "center",
        }}
      >
        <Button
          size="large"
          variant="contained"
          startIcon={<SaveAltIcon />}
          onClick={handleFormSubmitClick}
        >
          Import Group
        </Button>
      </Box>
    </Box>
  );
};

export default ADGroupForm;
