import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Tooltip, Select, MenuItem, FormControl, InputLabel, TextField, Switch, Autocomplete } from '@mui/material';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { DataGridPremium, useGridApiRef, useKeepGroupedColumnsHidden, GridColDef, GridState, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { CompleteRoleAssignment, CompleteRoleAssignmentType, getRowHoverText } from '../models/CompleteRoleAssignment';
import { useRoleAssignment } from '../RoleAssignmentProvider';
import RoleAssignmentService from '../services/RoleAssignmentService';
import { EmployeeType } from '../models/Employee'
import { RoleType } from '../models/Role'
import debounce from 'lodash.debounce';
import { useSnackbar } from 'notistack';
import EmployeeService from '../services/EmployeeService';
import RoleService from '../services/RoleService'
import HDStationIcon from '../components/icons/HDStationIcon.png';
import AMStationIcon from '../components/icons/AMStationIcon.jpeg';
import FMStationIcon from '../components/icons/FMStationIcon.png';
import SharedServicesIcon from '../components/icons/SharedServicesIcon.png';
import MarketIcon from '../components/icons/Market.jpeg';
import SimulcastStationIcon from '../components/icons/SimulcastStationIcon.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FilterCheckboxColumn from './CheckboxFilter';
window.RoleAssignmentService = RoleAssignmentService;

export default function RoleAssignmentManager() {
  const { currentCompleteRoleAssignmentModel, updateUI, expansionState, setExpansionState } = useRoleAssignment();
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState<EmployeeType[]>([]);
  const [employeeID, setEmployeeID] = useState('');
  const [roles, setRoles] = useState<RoleType[]>([]);
  const [ccRoleID, setCcRoleID] = useState('');
  const [groupingLevel, setGroupingLevel] = useState<number>(1);
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const [unsavedChangesDialogDisplayed, setUnsavedChangesDialogDisplayed] = useState(false);
  const [groupRows, setGroupRows] = useState<CompleteRoleAssignmentType[]>([]);
  const [rows, setRows] = useState<CompleteRoleAssignmentType[]>([]);
  const [nodeFilter, setNodeFilter] = useState('');
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
  const [treeFilterSelection, setTreeFilterSelection] = useState<string>('ShowAll');
  const [selectedRowsByFilterCheckbox, setSelectedRowsByFilterCheckbox] = useState<GridRowSelectionModel>([]);

  // Preload icons
  const preloadIcons = () => {
    const icons = {
      HDStationIcon: new Image(),
      AMStationIcon: new Image(),
      FMStationIcon: new Image(),
      SharedServicesIcon: new Image(),
      MarketIcon: new Image(),
      SimulcastStationIcon: new Image()
    };

    icons.HDStationIcon.src = HDStationIcon;
    icons.AMStationIcon.src = AMStationIcon;
    icons.FMStationIcon.src = FMStationIcon;
    icons.SharedServicesIcon.src = SharedServicesIcon;
    icons.MarketIcon.src = MarketIcon;
    icons.SimulcastStationIcon.src = SimulcastStationIcon;

    return icons;
  };

  const icons = preloadIcons();

  // Define the props type for MemoizedIcon
  interface MemoizedIconProps {
    src: string;
    alt: string;
  }

  // Create a memoized component with defined props
  const MemoizedIcon: React.FC<MemoizedIconProps> = React.memo(({ src, alt }) => (
    <img src={src} alt={alt} style={{ width: 20, height: 20 }} />
  ));

  //Rownode union type
  type RowNodeFixed = GridTreeNodeWithRender & {
    groupingKey?: string;
    id: string | number;
    type: 'group' | 'leaf' | 'footer';
    groupingField: string;
  };



  //Handler for Save button
  const handleSave = async () => {
    setLoading(true);
    try {
      await RoleAssignmentService.saveRoleAssignmentChanges();
      updateUI();
      enqueueSnackbar('Role assignments saved successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error saving role assignments:', error);
      enqueueSnackbar('Failed to save role assignments', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };


  //Effects
  const handleMouseEnter = useCallback((id: string) => {
    setActiveTooltip(id);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setActiveTooltip(null);
  }, []);

  //Displays icons for leaf nodes and Market-group
  const IconColumn: GridColDef = {
    field: 'icon',
    headerName: '',
    width: 50,
    renderCell: (params) => {
      const { row, rowNode } = params;
      const rowNodeWithFixed = rowNode as RowNodeFixed;
      const type = rowNodeWithFixed.type;
      const title = (
        <div style={{ fontSize: '1.0rem', whiteSpace: 'pre-wrap' }}>
          {getRowHoverText(row)}
        </div>
      );

      if (type === 'leaf') {
        const stationType = row.stationType?.toLowerCase() || '';
        const isSharedService = row.isSharedServiceBusinessUnit;

        if (stationType.includes('hd')) {
          return (
            <Tooltip
              title={title}
              onOpen={() => handleMouseEnter(row.id)}
              onClose={handleMouseLeave}
              open={activeTooltip === row.id}
              disableHoverListener={false}
              placement="right"
            >
              <span>
                <MemoizedIcon src={icons.HDStationIcon.src} alt="HD Station Icon" />
              </span>
            </Tooltip>
          );
        } else if (stationType.includes('am')) {
          return (
            <Tooltip
              title={title}
              onOpen={() => handleMouseEnter(row.id)}
              onClose={handleMouseLeave}
              open={activeTooltip === row.id}
              disableHoverListener={false}
              placement="right"
            >
              <span>
                <MemoizedIcon src={icons.AMStationIcon.src} alt="AM Station Icon" />
              </span>
            </Tooltip>
          );
        } else if (stationType.includes('fm')) {
          return (
            <Tooltip
              title={title}
              onOpen={() => handleMouseEnter(row.id)}
              onClose={handleMouseLeave}
              open={activeTooltip === row.id}
              disableHoverListener={false}
              placement="right"
            >
              <span>
                <MemoizedIcon src={icons.FMStationIcon.src} alt="FM Station Icon" />
              </span>
            </Tooltip>
          );
        } else if (stationType.includes('simulcast')) {
          return (
            <Tooltip
              title={title}
              onOpen={() => handleMouseEnter(row.id)}
              onClose={handleMouseLeave}
              open={activeTooltip === row.id}
              disableHoverListener={false}
              placement="right"
            >
              <span>
                <MemoizedIcon src={icons.SimulcastStationIcon.src} alt="Simulcast Station Icon" />
              </span>
            </Tooltip>
          );
        } else if (stationType.includes('shared')) {
          return (
            <Tooltip
              title={title}
              onOpen={() => handleMouseEnter(row.id)}
              onClose={handleMouseLeave}
              open={activeTooltip === row.id}
              disableHoverListener={false}
              placement="right"
            >
              <span>
                <MemoizedIcon src={icons.SharedServicesIcon.src} alt="Shared Services Icon" />
              </span>
            </Tooltip>
          );
        } else if (isSharedService > 0) {
          return (
            <Tooltip
              title={title}
              onOpen={() => handleMouseEnter(row.id)}
              onClose={handleMouseLeave}
              open={activeTooltip === row.id}
              disableHoverListener={false}
              placement="right"
            >
              <span>
                <MemoizedIcon src={icons.SharedServicesIcon.src} alt="Shared Services Icon" />
              </span>
            </Tooltip>
          );
        }
      }

      if (type === 'group' && rowNodeWithFixed.groupingField === 'MktOrBranchOrCorpDeptLongName') {
        const groupRow = groupRows.find(row => row.nodeName === rowNodeWithFixed.groupingKey);

        if (groupRow) {
          const uniqueId = groupRow.nodeID.toString();
          const groupTitle = (
            <div style={{ fontSize: '1.0rem', whiteSpace: 'pre-wrap' }}>
              {getRowHoverText(groupRow)}
            </div>
          );
          return (
            <Tooltip
              title={groupTitle}
              onOpen={() => handleMouseEnter(uniqueId)}
              onClose={handleMouseLeave}
              open={activeTooltip === uniqueId}
              disableHoverListener={false}
              placement="right"
            >
              <span>
                <MemoizedIcon src={icons.MarketIcon.src} alt="Market Icon" />
              </span>
            </Tooltip>
          );
        }
      }

      return null;
    },
  };

  // set the expansion level on the tree based on changes to the UI expansion level selection
  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.setState((state) => ({
        ...state,
        rowGrouping: {
          ...state.rowGrouping,
          expansionDepth: groupingLevel,
        },
      }));
      apiRef.current.forceUpdate();
    }
  }, [groupingLevel, apiRef]);

  useEffect(() => {
    if (apiRef.current && expansionState.length > 0) {
      apiRef.current.setState((state) => ({
        ...state,
        rowGrouping: {
          ...state.rowGrouping,
          model: expansionState.map(String), // Ensure all IDs are strings
        },
      }));
      apiRef.current.forceUpdate();
    }
  }, [expansionState, apiRef]);


  const handleGridStateChange = (state: GridState) => {
    const expandedRowIds = state.rowGrouping?.model.map(String) || [];
    setExpansionState(expandedRowIds);
  };

  //Handler for toggle Assignment button 
  const handleToggleAssignment = (row: CompleteRoleAssignmentType) => {
    if (row.assignmentStatus === 'assigned') {
      console.log("converting on toggle row in the grid to a complete role assignment", row)
      const roleAssignmentToDelete = CompleteRoleAssignment.toRoleAssignment(row);
      console.log("converted on toggle row in the grid to a complete role assignment", roleAssignmentToDelete)
      RoleAssignmentService.removeRoleAssignment(roleAssignmentToDelete);
    } else {
      console.log(" adding an assignment for later are the props available ?", row)
      RoleAssignmentService.addRoleAssignment(row.nodeID, row.employeeID, row.roleID);
    }
    updateUI();
  };

  //Handler for toggle group assignment button
  const handleGroupToggleAssignment = (groupName: string) => {
    const groupRow = groupRows.find(row => row.nodeName === groupName);
    if (groupRow) {
      handleToggleAssignment(groupRow);
    } else {
      console.warn(`Group row not found for groupName: ${groupName}`);
    }
  };

  const setDisplayGroups = () => {
    let groupLevels = [0,1,2,3,4,5,6]
    let groupRows = currentCompleteRoleAssignmentModel.filter(row => groupLevels.includes(row.level));
    setGroupRows(groupRows);
  };

  const getUnfilteredRows = () => {
    let unfilteredRows = currentCompleteRoleAssignmentModel.filter(row => row.level === 7);
    return unfilteredRows;
  };


  //update tree displayed on model update and selection of filter.
  useEffect(() => {
    applyFilterSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompleteRoleAssignmentModel, treeFilterSelection]);


  const handletreeFilterSelectionChange = (event: any) => {
    const value = event.target.value;
    setTreeFilterSelection(value);
  };

  const handleNodeFilterChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newNodeFilter = event.target.value;
    setNodeFilter(newNodeFilter);  // Update the state
    applyFilterSelection();  // Re-apply the filter with the current selection
  };

  const applyFilterSelection = () => {
    try {
      setDisplayGroups()
      let displayRows = getUnfilteredRows()
      if (treeFilterSelection === 'AssignedOnly') {
        displayRows = displayRows.filter(row => row.isAssigned === 1 || row.isPermitted === 1);
      }
      else if (treeFilterSelection === 'RadioMarket') {
        displayRows = displayRows.filter(row => row.subsidiaryorLOBLongName === "iHM Markets Group");
      }
      else if (treeFilterSelection === 'CheckedOnly') {
        if (selectedRowsByFilterCheckbox.length === 0) {
          enqueueSnackbar('Please select Checkboxes before clicking on Show Checked option.', { variant: 'info' });
          return
        }
        displayRows = displayRows.filter(row => selectedRowsByFilterCheckbox.includes(row.id));
      }
      // Apply the node filter
      if (nodeFilter) {
        displayRows = displayRows.filter(row =>
          row.nodeName && row.nodeName.toLowerCase().includes(nodeFilter.toLowerCase())
        );
      }
      setRows(displayRows.map(row => ({ ...row, title: getRowHoverText(row) })));
    } catch (error) {
      console.error('Error saving filter option:', error);
    }
  };


  //handle confirm delete option
  const handleConfirmRefresh = (e: any) => {
    console.log("handling confirm refresh")
    setUnsavedChangesDialogDisplayed(false);
    loadRoleAssignments(true);
  }
  //handle cancel delete option
  const handleCancelRefresh = () => {
    console.log("handling cancel refresh")
    setUnsavedChangesDialogDisplayed(false)
  }

  //confirmation window after refresh button is clicked
  const handleRefreshClick = () => {
    // console.log("checking for unsaved changes")
    let unsavedChanges = RoleAssignmentService.checkIfUnsavedChanges()
    // console.log("unsaved changes", unsavedChanges)
    if (!unsavedChanges) {
      loadRoleAssignments(true);
    }
    else
      setUnsavedChangesDialogDisplayed(true)

  };

  //Handler for Refresh button click
  const loadRoleAssignments = async (dropUnsavedChanges: boolean) => {
    try {
      if (!employeeID || !ccRoleID) {
        enqueueSnackbar('Employee ID and CC Role ID must be provided', { variant: 'error' });
        return;
      }
      // const dropUnsavedChanges = handleRefreshClick();
      // if (!dropUnsavedChanges) {
      //   return
      // }
      setLoading(true);
      await RoleAssignmentService.loadCompleteAssignments(employeeID, ccRoleID, dropUnsavedChanges);
      updateUI();
    } catch (error) {
      console.error('Error loading role assignments:', error);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEmployeeIDChange = useCallback(
    debounce((value) => {
      setEmployeeID(value);
    }, 10),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCcRoleIDChange = useCallback(
    debounce((value) => {
      setCcRoleID(value);
    }, 10),
    []
  );

  useEffect(() => {
    return () => {
      handleEmployeeIDChange.cancel();
      handleCcRoleIDChange.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Fetches and sets all 'Employee' list when UI is started.
  useEffect(() => {
    async function fetchEmployees() {
      try {
        setLoading(true);
        const employeeList = await EmployeeService.getEmployees();
        setEmployees(employeeList);
      } catch (error) {
        console.error('Failed to fetch employees', error);
      }
      finally {
        setLoading(false);
      }
    }
    fetchEmployees();
  }, []);


  //Handler for selected Employee ID
  const handleEmployeeSelect = (event: any, value: EmployeeType | null) => {
    if (value) {
      setEmployeeID(value.employeeID);
      RoleAssignmentService.updateContextWithEmployeeID(value.employeeID);
    }
  };

  //Handler for selected Role ID
  const handleRoleSelect = (event: any, value: RoleType | null) => {
    if (value) {
      setCcRoleID(value.roleID.toString());
      RoleAssignmentService.updateContextWithRoleID(value.roleID.toString());
    }
  };

  //Fetches and sets all 'Role' list when UI is started.
  useEffect(() => {
    async function fetchRoles() {
      try {
        setLoading(true);
        const roleList = await RoleService.getRoles();
        setRoles(roleList);
      } catch (error) {
        console.error('Failed to fetch roles', error);
      } finally {
        setLoading(false)
      }
    }
    fetchRoles();
  }, []);

  //Utility for 'Assignment Switch' Data Column in the Grid
  const AssignmentSwitchColumn: GridColDef = {
    field: 'assignmentSwitch',
    headerName: 'Assignment Switch',
    width: 150,
    renderCell: (params) => {
      let isAssigned = params.row.assignmentStatus === 'assigned';
      let isPermitted = params.row.assignmentStatus === 'permitted';
      const isGroup = params.rowNode.type === 'group';

      //For group rows
      if (isGroup) {
        const rowNodeWithGroupingKey = params.rowNode as RowNodeFixed;
        const groupRow = groupRows.find(row => row.nodeName === rowNodeWithGroupingKey.groupingKey);
        if (groupRow) {
          isAssigned = groupRow.assignmentStatus === 'assigned';
          isPermitted = groupRow.assignmentStatus === 'permitted';
        }
      }

      //Perform assignment switch
      const handleChange = () => {
        if (isPermitted) {
          enqueueSnackbar('The update you are attempting is not supported. If you desire to make a change to role assignment at this row, you must unassign the parent node assignment that has currently cascaded to this row.', { variant: 'error' });
          return;
        }

        if (isGroup) {
          const rowNodeWithGroupingKey = params.rowNode as RowNodeFixed;
          handleGroupToggleAssignment(rowNodeWithGroupingKey.groupingKey!);
        } else {
          handleToggleAssignment(params.row);
        }
      };

      return (
        <Switch
          checked={isAssigned || isPermitted}
          onChange={handleChange}
          color="primary"
        />
      );
    },
  };

  //Utility for 'Permitted Count' Column in the Grid
  const GroupColumnPermittedCount: GridColDef = {
    field: 'PermittedCount',
    headerName: 'Permitted Count',
    type: 'string',
    width: 150,
    renderCell: (params) => {
      const getChildElementsPermittedCount = (groupName: string): string | "" => {
        const groupRow = groupRows.find(row => row.nodeName === groupName);
        let groupPermittedCount = "";
        if (groupRow) {
          groupPermittedCount = groupRow.descendantPermittedCount?.toString() || "";
          return groupPermittedCount;
        } else {
          return "";
        }
      };

      //Sets background color for permitted_count > 0
      let backgroundColor = '';
      if (params.rowNode.type === 'group') {
        const permittedCount = getChildElementsPermittedCount(params.rowNode.groupingKey as string);
        backgroundColor = permittedCount !== '' && permittedCount !== '0' ? '#c8e6c9' : '#f5f5f5';

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor, padding: '8px' }}>
            <span style={{ marginLeft: 2, marginRight: 8 }}><b>{permittedCount}</b></span>
          </Box>
        );
      }

      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginLeft: 2, marginRight: 8 }}><b>{params.value}</b></span>
        </Box>
      );
    },
  };

  //Utility for 'Assigned Count' Column in the Grid
  const GroupColumnAssignedCount: GridColDef = {
    field: 'AssignedCount',
    headerName: 'Assigned Count',
    type: 'string',
    width: 150,
    renderCell: (params) => {
      const getChildElementsAssignedCount = (groupName: string): string | "" => {
        const groupRow = groupRows.find(row => row.nodeName === groupName);
        let groupAssignedCount = "";
        if (groupRow) {
          groupAssignedCount = groupRow.descendantAssignedCount?.toString() || "";
          return groupAssignedCount;
        } else {
          return "";
        }
      };

      //Sets background color for permitted_count > 0
      let backgroundColor = '';
      if (params.rowNode.type === 'group') {
        const assignedCount = getChildElementsAssignedCount(params.rowNode.groupingKey as string);
        backgroundColor = assignedCount !== '' && assignedCount !== '0' ? '#c8e6c9' : '#f5f5f5';

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor, padding: '8px' }}>
            <span style={{ marginLeft: 2, marginRight: 8 }}><b>{assignedCount}</b></span>
          </Box>
        );
      }

      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginLeft: 2, marginRight: 8 }}><b>{params.value}</b></span>
        </Box>
      );
    },
  };

  //Utility for 'Is Assigned' Column in the Grid
  const IsAssignedColumn: GridColDef = {
    field: 'isAssigned',
    headerName: 'Is Assigned',
    type: 'string',
    width: 150,
    renderCell: (params) => {
      const getIsAssigned = (groupName: string): string | "" => {
        const groupRow = groupRows.find(row => row.nodeName === groupName);
        if (groupRow) {
          if (groupRow.isAssigned === undefined || groupRow.isAssigned === null) {
            return "0";
          }
          return groupRow.isAssigned.toString();
        }
        return "0";
      };

      let backgroundColor = '';
      let assignedValue = "0";

      if (params.row.isAssigned !== undefined && params.row.isAssigned !== null) {
        assignedValue = params.row.isAssigned.toString();
      }

      if (params.rowNode.type === 'group') {
        assignedValue = getIsAssigned(params.rowNode.groupingKey as string);
        backgroundColor = assignedValue !== '' && assignedValue !== '0' ? '#c8e6c9' : '#f5f5f5';
      } else {
        backgroundColor = params.row.isAssigned ? '#c8e6c9' : '#f5f5f5';
      }

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor, padding: '8px' }}>
          <span style={{ marginLeft: 2, marginRight: 8 }}><b>{assignedValue}</b></span>
        </Box>
      );
    },
  };

  //All columns of the Grid
  const columns: GridColDef[] = [
    FilterCheckboxColumn(apiRef),
    IconColumn,
    AssignmentSwitchColumn,
    GroupColumnAssignedCount,
    GroupColumnPermittedCount,
    { field: 'nodeName', headerName: 'Node Name', width: 150 },
    IsAssignedColumn,
    { field: 'isPermitted', headerName: 'is Permitted', width: 150 },
    { field: 'descendantAssignedCount', headerName: 'Descendants Assigned', width: 150 },
    { field: 'descendantPermittedCount', headerName: 'Descendants Permitted', width: 150 },
    { field: 'assignmentType', headerName: 'Assignment Type', width: 150, editable: true },
    { field: 'assignmentStatus', headerName: 'Assignment Status', width: 300 },
    { field: 'isMarket', headerName: 'Is Market', width: 150 },
    { field: 'marketID', headerName: 'Market ID', width: 150 },
    { field: 'marketName', headerName: 'Market Name', width: 150 },
    { field: 'businessUnitID', headerName: ' Business Unit ID', width: 150 },
    { field: 'businessUnitName', headerName: 'Business Unit Name', width: 150 },
    { field: 'isSharedServiceBusinessUnit', headerName: 'Is Shared Service', width: 150 },
    { field: 'isStation', headerName: 'Is Station', width: 150 },
    { field: 'stationType', headerName: 'Station Type', width: 150 },
    { field: 'fccID', headerName: 'FCC ID', width: 150 },
    { field: 'level', headerName: 'Level', width: 150 },
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'CCRoleID', headerName: 'CC Role ID', width: 150 },
    { field: 'employeeID', headerName: 'Employee ID', width: 150 },
    { field: 'roleAssignmentID', headerName: 'Role Assignment ID', width: 150 },
    { field: 'totalCompanyLongName', headerName: 'Total Company', width: 150 },
    { field: 'divisionLongName', headerName: 'Division', width: 150 },
    { field: 'level2LongName', headerName: 'Level 2', width: 150 },
    { field: 'subsidiaryorLOBLongName', headerName: 'Subsidiary/LOB', width: 150 },
    { field: 'consolidatedRegionsLongName', headerName: 'Consolidated Regions', width: 150 },
    { field: 'regionLongName', headerName: 'Region', width: 150 },
    { field: 'BUOrCostCenterOrStationLongName', headerName: 'BU/BOU/Cost Center/Station', width: 150 },
    { field: 'MktOrBranchOrCorpDeptLongName', headerName: 'Market/Branch/Corp Dept', width: 150 },
    { field: 'parentNode', headerName: 'Parent Node', width: 150 },
    { field: 'nodeID', headerName: 'Node ID', width: 150 },
    { field: 'fullyQualifiedNodeDescription', headerName: 'Fully Qualified Node Description', width: 150 },
    { field: 'reverseFullyQualifiedNodeDescription', headerName: 'Reverse Fully Qualified Node Description', width: 1000 }
  ];

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      columns: {
        columnVisibilityModel: {
          nodeName: false,
          isAssigned: true,
          isPermitted: false,
          descendantAssignedCount: false,
          descendantPermittedCount: false,
          assignmentStatus: false,
          fullyQualifiedNodeDescription: false,
          level: false,
          assignmentType: false,
          isMarket: false,
          marketID: false,
          marketName: false,
          businessUnitID: false,
          businessUnitName: false,
          isSharedServiceBusinessUnit: false,
          isStation: false,
          stationType: false,
          fccID: false,
          id: false,
          CCRoleID: false,
          employeeID: false,
          roleAssignmentID: false,
          reverseFullyQualifiedNodeDescription: false,
          totalCompanyLongName: false,
          divisionLongName: false,
          level2LongName: false,
          subsidiaryorLOBLongName: false,
          consolidatedRegionsLongName: false,
          regionLongName: false,
          BUOrCostCenterOrStationLongName: false,
          MktOrBranchOrCorpDeptLongName: false,
          parentNode: false,
          nodeID: false
        }
      },
      rowGrouping: {
        model: ['totalCompanyLongName', 'divisionLongName', 'level2LongName', 'subsidiaryorLOBLongName', 'consolidatedRegionsLongName', 'regionLongName', 'MktOrBranchOrCorpDeptLongName']
      },
      pagination: { paginationModel: { pageSize: 100 } },
      sorting: { sortModel: [{ field: 'fullyQualifiedNodeDescription', sort: 'asc' }] }
    }
  });


  return (
    <Box sx={{ height: 'calc(100vh - 45px)', width: '100%', paddingTop: '64px'}}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <FormControl sx={{ marginRight: 2, minWidth: 60, color: 'primary' }}>
          <InputLabel>
            <FilterAltRoundedIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
          </InputLabel>
          <Select
            color="primary"
            value={treeFilterSelection}
            label="Filter"
            onChange={handletreeFilterSelectionChange}
          >
            <MenuItem value={"ShowAll"}>Show All</MenuItem>
            <MenuItem value={"AssignedOnly"}>Show Assigned</MenuItem>
            <MenuItem value={"RadioMarket"}>Show Radio Markets</MenuItem>
            <MenuItem value={"CheckedOnly"}>Show Checked</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <FormControl sx={{ marginRight: 2, minWidth: 120 }}>
            <InputLabel>Level</InputLabel>
            <Select
              value={groupingLevel}
              onChange={(e) => setGroupingLevel(e.target.value as number)}
              label="Level"
            >
              {[...Array(8).keys()].map(level => (
                <MenuItem key={level} value={level}>{`Level ${level} nodes`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {/* <Box 
          sx={{ 
            border: '1px solid', 
            borderColor: 'grey.400', 
            borderRadius: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            padding: '1px', // Adds padding inside the box
            marginRight: 2, 
            height: '56px'  // Match height to that of the TextField
          }}
        >
          <FormLabel 
            sx={{ 
              color: 'text.secondary', 
              fontWeight: 400, 
              fontSize: '.75rem', 
              marginBottom: '1px', // Space between label and checkbox
              position: 'relative', 
              top: '-10px', // helps adjust it above the checkbox
              background: 'white', // Match background to the FormControl
              padding: '0 4px', // To create a seamless look
              zIndex: 1, // Ensure it appears above the checkbox
            }}
          >
            Markets Only
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={showMarketsOnly}
                onChange={handleShowMarketsOnlyChange}
                color="primary"
                sx={{ 
                  margin: 0, 
                  alignSelf: 'flex-start', 
                  marginTop: '-2px', // Adjust this if you need to move the checkbox up
                }} 
              />
            }
            label=""
          />
        </Box> */}



        <TextField
          label="Node Filter"
          variant="outlined"
          value={nodeFilter}
          onChange={handleNodeFilterChanged}  // Call this handler when input changes
          sx={{ marginRight: 2, width: 300 }}
          placeholder="Filter by Node"
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            options={employees}
            getOptionLabel={(option) => option.fullNameWithID || ""}
            onChange={handleEmployeeSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Employee"
                variant="outlined"
                sx={{ marginRight: 2, width: 300 }}
              />
            )}
          />
          <Autocomplete
            options={roles}
            getOptionLabel={(option) => option.roleDescriptionWithID || ""}
            onChange={handleRoleSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Role"
                variant="outlined"
                sx={{ marginRight: 2, width: 300 }}
              />
            )}
          />
          <Tooltip title="Refresh Employee and Role">
            <Button
              variant="contained"
              color="primary"
              sx={{ marginRight: 2, height: 56 }}
              // onClick={loadRoleAssignments}
              onClick={handleRefreshClick}
              startIcon={<RefreshIcon />}
            />
          </Tooltip>
          <Button
            variant="contained"
            color="primary"
            sx={{ height: 56 }}
            startIcon={<SaveIcon />}
            onClick={handleSave}
            disabled={loading}
          />
        </Box>
      </Box>
      <DataGridPremium
        rows={rows}
        apiRef={apiRef}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectedRowsByFilterCheckbox(newRowSelectionModel);
        }}
        rowSelectionModel={selectedRowsByFilterCheckbox}
        pageSizeOptions={[{ value: 100, label: 'small' }, { value: 1000, label: 'huge' }]}
        initialState={initialState}
        defaultGroupingExpansionDepth={groupingLevel}
        rowGroupingColumnMode="single"
        groupingColDef={{ width: 350, leafField: 'nodeName' }}
        onStateChange={handleGridStateChange}
        sx={{ height: '100%' }}
        loading={loading}
      />
      <Dialog //dialog-box when delete-cell icon is clicked
        open={unsavedChangesDialogDisplayed}
        onClose={handleCancelRefresh}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Page Refresh"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to refresh the page? Click Yes to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelRefresh} color="primary">
            Cancel
          </Button>
          <Button onClick={(e) => handleConfirmRefresh(e)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
