import { useCallback, useEffect, useState } from "react";
import { AutoRoleAssignmentRuleType } from "../models";
import { AutomaticRoleAssignmentRuleService } from "../services";
import { enqueueSnackbar } from "notistack";

const FETCH_ERROR_MESSAGE = "Something went wrong. Please try again later.";

const useAutomationRuleDetail = (ruleId: string) => {
  const [ruleDetail, setRuleDetail] = useState<AutoRoleAssignmentRuleType>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchAutomatedRuleDetails = useCallback(async () => {
    try {
      setLoading(true);
      const rule =
        await AutomaticRoleAssignmentRuleService.getAutomaticRoleAssignmentRuleDetail(
          ruleId
        );
      setRuleDetail(rule);
    } catch (error) {
      setError(error);
      enqueueSnackbar(FETCH_ERROR_MESSAGE, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [ruleId]);

  useEffect(() => {
    fetchAutomatedRuleDetails();
  }, [fetchAutomatedRuleDetails]);

  return { ruleDetail, loading, error };
};

export default useAutomationRuleDetail;
