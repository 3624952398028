import { useEffect, useState } from "react";
import { JobCodeType } from "../models";
import { JobCodeService } from "../services";

const useJobCodes = () => {
  const [jobCodes, setJobCodes] = useState<JobCodeType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        setLoading(true);
        const roleList = await JobCodeService.getJobCodes();
        setJobCodes(roleList);
      } catch (error) {
        console.error("Failed to fetch job codes", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchRoles();
  }, []);

  return { jobCodes, loading, error };
};

export default useJobCodes;
