import APIService from "../api/APIService";
import { ADGroupRuleExclusionType } from "../models";

class ADGroupRuleExclusionService {
  async getADGroupRuleExclusions(
    ruleId: string
  ): Promise<ADGroupRuleExclusionType[]> {
    try {
      const exclusions: ADGroupRuleExclusionType[] =
        await APIService.getADGroupRuleExclusions(ruleId);
      return exclusions;
    } catch (error) {
      console.error(
        "Error fetching automatic Entra group membership exclusions:",
        error
      );
      throw error;
    }
  }

  async createADGroupRuleExclusion(
    ruleExclusion: ADGroupRuleExclusionType
  ): Promise<ADGroupRuleExclusionType> {
    try {
      const newEclusion: ADGroupRuleExclusionType =
        await APIService.createADGroupRuleExclusion(ruleExclusion);
      return newEclusion;
    } catch (error) {
      console.error(
        "Error creating new automatic Entra group membership exclusion:",
        error
      );
      throw error;
    }
  }

  async deleteADGroupRuleExclusion(exclusionId: string): Promise<void> {
    try {
      await APIService.deleteADGroupRuleExclusion(exclusionId);
    } catch (error) {
      console.error(
        "Error deleting automatic Entra group membership exclusion:",
        error
      );
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ADGroupRuleExclusionService();
