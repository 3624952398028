import {
  Configuration,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import { ReactNode } from "react";

export const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_APP_CLIENT_ID as string,
    redirectUri: process.env.REACT_APP_AZURE_APP_REDIRECT_URI,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
  },
};

export const pca = new PublicClientApplication(configuration);

export const scopes = ["User.Read"];

export async function getAccessToken() {
  const accounts = pca.getAllAccounts();

  const request = {
    scopes,
    account: accounts[0],
  };

  const authResult = await pca.acquireTokenSilent(request);

  return {
    token: authResult.accessToken,
  };
}

export function usingAuthentication() {
  return process.env?.REACT_APP_USE_AUTH !== "0";
}

export function WithAuthentication({ children }: { children: ReactNode }) {
  // this hook will automatically issue a redirect login if the user isn't logged in yet
  useMsalAuthentication(InteractionType.Redirect, { scopes });

  // Everything below `AuthenticatedTemplate` will only render if the user is logged in
  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
}
