import { useCallback, useEffect, useState } from "react";
import { ADGroupType } from "../models";
import { EntraADGroupService } from "../services";

const FETCH_ERROR_MESSAGE = "Failed to fetch ad groups from Entra";

const useEntraADGroups = (groupName?: string) => {
  const [entraADGroups, setEntraADGroups] = useState<ADGroupType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchEntraADGroups = useCallback(async (groupName?: string) => {
    try {
      setLoading(true);
      const regGroups = await EntraADGroupService.getEntraADGroups(groupName);
      setEntraADGroups(regGroups);
    } catch (error) {
      console.error(FETCH_ERROR_MESSAGE, error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEntraADGroups();
  }, [fetchEntraADGroups]);

  useEffect(() => {
    fetchEntraADGroups(groupName);
  }, [fetchEntraADGroups, groupName]);

  return { entraADGroups, loading, error };
};

export default useEntraADGroups;
