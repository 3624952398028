import { startCase } from "lodash";
import { AutomaticRoleAssignmentRuleExclusionType } from "../../models";
import { AutomationRuleExclusionFormErrorType } from "./interface";

export const validateAutomationRuleExclusionForm = (
  form: AutomaticRoleAssignmentRuleExclusionType
) => {
  const errors: AutomationRuleExclusionFormErrorType = {};

  const requiredFields: (keyof AutomationRuleExclusionFormErrorType)[] = [
    "notes",
    "employeeId",
  ];

  requiredFields.forEach((field) => {
    if (!form[field]) {
      errors[field] = `${startCase(field)} is required`;
    }
  });

  return errors;
};

export default validateAutomationRuleExclusionForm;
