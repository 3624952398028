import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import { AutomationRuleExclusionFormErrorType, Props } from "./interface";
import { BLANK_ERROR_MESSAGE, TEXT_FIELD_STYLES } from "../../constants";
import { useContext, useState } from "react";
import { AutomaticRoleAssignmentRuleExclusionType } from "../../models";
import validateAutomationRuleExclusionForm from "./utils";
import { EmployeesContext } from "../../context";

const GRID_SIZE_PROPS = { xs: 12, md: 6, lg: 4 };

const AutomationRuleExclusionForm = ({
  ruleId,
  onCancel,
  onFormSubmit,
}: Props) => {
  const EMPTY_FORM_VALUE = {
    employeeId: "",
    automaticAssignmentRuleId: ruleId,
    notes: "",
  };

  const [formFields, setFormFields] =
    useState<AutomaticRoleAssignmentRuleExclusionType>(EMPTY_FORM_VALUE);
  const [formError, setFormError] =
    useState<AutomationRuleExclusionFormErrorType>();

  const { employees, loading } = useContext(EmployeesContext);

  const onFormChange = (
    key: keyof AutomaticRoleAssignmentRuleExclusionType,
    value: string | number
  ) => {
    setFormFields((prev) => {
      if (!prev) return { [key]: value };
      return { ...prev, [key]: value };
    });
  };

  const handleFormSubmitClick = () => {
    const errors = validateAutomationRuleExclusionForm(formFields);
    if (Object.keys(errors).length) {
      return setFormError(errors);
    }
    onFormSubmit(formFields);
  };

  return (
    <Box
      sx={{
        padding: 1,
        borderRadius: 2,
        border: "1px solid lightgray",
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AddIcon sx={{ marginRight: 0.5 }} />
          <Typography variant="h6" component="h4">
            Add Automatic Role Assignment Exclusion
          </Typography>
        </Box>
        <IconButton color="error" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item {...GRID_SIZE_PROPS}>
          <TextField
            required
            sx={TEXT_FIELD_STYLES}
            label="Notes"
            variant="outlined"
            value={formFields?.notes}
            onChange={(e) => {
              onFormChange("notes", e.target.value);
            }}
            error={Boolean(formError?.notes)}
            helperText={formError?.notes || BLANK_ERROR_MESSAGE}
          />
        </Grid>

        <Grid item {...GRID_SIZE_PROPS}>
          <Autocomplete
            loading={loading}
            options={employees}
            getOptionLabel={(option) => option.fullNameWithID || ""}
            onChange={(_, value) =>
              onFormChange("employeeId", value?.employeeID || "")
            }
            value={
              employees.find(
                (employee) => employee.employeeID === formFields?.employeeId
              ) || null
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Employee"
                variant="outlined"
                sx={TEXT_FIELD_STYLES}
                error={Boolean(formError?.employeeId)}
                helperText={formError?.employeeId || BLANK_ERROR_MESSAGE}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 4,
          alignItems: "center",
        }}
      >
        <Button
          size="large"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleFormSubmitClick}
        >
          Add New Exclusion
        </Button>
      </Box>
    </Box>
  );
};

export default AutomationRuleExclusionForm;
