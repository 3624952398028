import { useCallback, useEffect, useState } from "react";
import { ADGroupRuleType } from "../models";
import { ADGroupRuleService } from "../services";
import { enqueueSnackbar } from "notistack";

const FETCH_ERROR_MESSAGE = "Something went wrong. Please try again later.";

const useADGroupRuleDetail = (ruleId: string) => {
  const [ruleDetail, setRuleDetail] = useState<ADGroupRuleType>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchADGroupRuleDetails = useCallback(async () => {
    try {
      setLoading(true);
      const rule = await ADGroupRuleService.getADGroupRuleDetail(ruleId);
      setRuleDetail(rule);
    } catch (error) {
      setError(error);
      enqueueSnackbar(FETCH_ERROR_MESSAGE, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [ruleId]);

  useEffect(() => {
    fetchADGroupRuleDetails();
  }, [fetchADGroupRuleDetails]);

  return { ruleDetail, loading, error };
};

export default useADGroupRuleDetail;
