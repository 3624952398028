const api_config = {
  apiTimeouts: {
    getOrgHierarchy: 15000, // 15 seconds
    getRoles: 30000, // 30 seconds
    saveRole: 10000, // 10 seconds
    deleteRole: 10000, // 10 seconds
    getEmployees: 30000, // 30 seconds
    getRoleAssignments: 30000, // 30 seconds
    addRoleAssignment: 10000, // 10 seconds
    deleteRoleAssignment: 10000, // 10 seconds
    getJobCodes: 30000, // 30 seconds
    getAutomaticRoleAssignmentRules: 30000, // 30 seconds
    addAutomaticRoleAssignmentRule: 10000, // 10 seconds
    saveAutomaticRoleAssignmentRule: 10000, // 10 seconds
    getEntraADGroups: 30000, // 30 seconds
    getADGroups: 30000, // 30 seconds
    importADGroup: 10000, // 10 seconds
    deleteADGroup: 10000, // 10 seconds
    getADGroupRules: 30000, // 30 seconds
    createADGroupRule: 10000, // 10 seconds
    updateADGroupRule: 10000, // 10 seconds
    addMultipleRoleAssignment: 30000, // 30 seconds
    getAutomaticRoleAssignmentRuleDetail: 10000, // 10 seconds
    getAutomaticRoleAssignmentRuleExclusions: 30000, // 30 seconds
    createAutomaticRoleAssignmentRuleExclusion: 10000, // 10 seconds
    deleteAutomaticRoleAssignmentRuleExclusion: 10000, // 10 seconds
    getADGroupRuleDetail: 10000, // 10 seconds
    getADGroupRuleExclusions: 30000, // 30 seconds
    createADGroupRuleExclusions: 10000, // 10 seconds
    deleteADGroupRuleExclusions: 10000, // 10 seconds
  },
  apiBaseURL: process.env.REACT_APP_API_BASE_URL,
};

export default api_config;
