import { createContext, ReactNode } from "react";
import { EmployeeType } from "../models";
import { useEmployees } from "../hooks";

type EmployeesContextType = {
  employees: EmployeeType[];
  loading: boolean;
  error: unknown | null;
};

const EmployeesContext = createContext<EmployeesContextType>({
  employees: [],
  loading: false,
  error: null,
});

export const EmployeesContextProvider = (props: { children: ReactNode }) => {
  const { employees, loading, error } = useEmployees();

  return (
    <EmployeesContext.Provider value={{ employees, loading, error }}>
      {props.children}
    </EmployeesContext.Provider>
  );
};

export default EmployeesContext;
