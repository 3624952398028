import {
  AppBar,
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { ROUTES, SPACING } from "../constants";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { isRouteOnADGroupSubdomain } from "../constants/routes";

// TODO: Remove this constant when AD Group functionality is ready
const DISPLAY_AD_GROUPS = false;

const {
  SIMPLIFIED_ROLE_ASSIGNMENT,
  ROLES,
  ROLE_ASSIGNMENT_RULES,
  AD_GROUP_RULES,
  IMPORT_AD_GROUPS,
} = ROUTES;
const { DRAWER_WIDTH, TOOLBAR_HEIGHT } = SPACING;

const NavDrawer = (): ReactElement => {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(isRouteOnADGroupSubdomain(pathname));

  useEffect(() => {
    if (isRouteOnADGroupSubdomain(pathname)) {
      setOpen(true);
    }
  }, [pathname]);

  const drawerStyle = {
    width: DRAWER_WIDTH,
    "& .MuiBackdrop-root": { display: "none" },
  };

  return (
    <div>
      <AppBar position="fixed" sx={{ zIndex: 1300 }}>
        <Toolbar>
          <Typography variant="h6">Security Roles Administration</Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        disableEnforceFocus
        variant="temporary"
        open={true}
        sx={drawerStyle}
        PaperProps={{
          sx: drawerStyle,
          elevation: 9,
        }}
      >
        <List style={{ marginTop: TOOLBAR_HEIGHT }}>
          <ListItem key={SIMPLIFIED_ROLE_ASSIGNMENT.label}>
            <Link to={SIMPLIFIED_ROLE_ASSIGNMENT.url}>
              {SIMPLIFIED_ROLE_ASSIGNMENT.label}
            </Link>
          </ListItem>
          <ListItem key={ROLES.label}>
            <Link to={ROLES.url}>{ROLES.label}</Link>
          </ListItem>
          <ListItem key={ROLE_ASSIGNMENT_RULES.label}>
            <Link to={ROLE_ASSIGNMENT_RULES.url}>
              {ROLE_ASSIGNMENT_RULES.label}
            </Link>
          </ListItem>
          {/* TODO: Remove this constant when AD Group functionality is ready */}
          {DISPLAY_AD_GROUPS && (
            <>
              <ListItem key={AD_GROUP_RULES.label}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Link to={AD_GROUP_RULES.url}>{AD_GROUP_RULES.label}</Link>
                  <IconButton
                    sx={{ height: "fit-content" }}
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Box>
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <ListItem sx={{ marginLeft: 2 }} key={IMPORT_AD_GROUPS.label}>
                  <Link to={IMPORT_AD_GROUPS.url}>
                    {IMPORT_AD_GROUPS.label}
                  </Link>
                </ListItem>
              </Collapse>
            </>
          )}
        </List>
      </Drawer>
      <main
        style={{
          marginTop: TOOLBAR_HEIGHT,
          marginLeft: DRAWER_WIDTH,
          padding: 4,
          paddingLeft: 20,
        }}
      >
        <Outlet />
      </main>
    </div>
  );
};

export default NavDrawer;
