import { useCallback, useEffect, useState } from "react";
import { EmployeeType } from "../models";
import { EmployeeService } from "../services";

const FETCH_ERROR_MESSAGE = "Failed to fetch employees";

const useEmployees = () => {
  const [employees, setEmployees] = useState<EmployeeType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchEmployees = useCallback(async () => {
    try {
      setLoading(true);
      const adRules = await EmployeeService.getEmployees();
      setEmployees(adRules);
    } catch (error) {
      console.error(FETCH_ERROR_MESSAGE, error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  return { employees, loading, error };
};

export default useEmployees;
