import {
  Edit as EditIcon,
  PersonRemove as PersonRemoveIcon,
} from "@mui/icons-material";
import { Box, Switch } from "@mui/material";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { Props } from "./interface";

const { AD_GROUP_EXCLUSIONS } = ROUTES;

const ADGroupRulesTable = ({
  loading,
  adGroupRules,
  onEditClick,
  onActiveOrDeactiveClick,
}: Props) => {
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      autoAdGroupAssignmentRulesId: false,
      inactivationBehavior: false,
    });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        id: 1,
        field: "active",
        operator: "equals",
        value: "true",
      },
    ],
  });

  const navigateTo = useNavigate();

  const ActionsColumn: GridColDef = {
    field: "actions",
    headerName: "Actions",
    type: "actions",
    getActions: (params) => {
      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => onEditClick(params.row)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<PersonRemoveIcon />}
          label="Manage Exclusions"
          onClick={() =>
            navigateTo(
              AD_GROUP_EXCLUSIONS.url.replace(
                ":ruleId",
                params.row.autoAdGroupAssignmentRulesId
              )
            )
          }
          color="inherit"
        />,
      ];
    },
  };

  const ActiveColumn: GridColDef = {
    field: "active",
    headerName: "Active",
    renderCell: (params) => {
      return (
        <Switch
          onChange={() => {
            onActiveOrDeactiveClick(params.row);
          }}
          checked={params.value}
        />
      );
    },
  };

  return (
    <Box sx={{ height: "calc(100vh - 216px)", width: "100%", marginTop: 3 }}>
      <DataGridPremium
        loading={loading}
        rowSelection={false}
        rows={adGroupRules || []}
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { quickFilterProps: { debounceMs: 300 } } }}
        autosizeOnMount
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
        filterModel={filterModel}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        getRowId={(row) => row.autoAdGroupAssignmentRulesId}
        columns={[
          ActionsColumn,
          {
            field: "autoAdGroupAssignmentRulesId",
            headerName: "ID",
          },
          ActiveColumn,
          {
            field: "shortDescription",
            headerName: "Short Description",
            width: 150,
          },
          { field: "adGroup", headerName: "Entra Group ID", width: 120 },
          { field: "role", headerName: "Role ID" },
          {
            field: "inactivationBehavior",
            headerName: "Inactivation Behavior",
            width: 150,
          },
        ]}
      />
    </Box>
  );
};

export default ADGroupRulesTable;
