import { Box, Button, Typography } from "@mui/material";
import { Launch as LauchIcon } from "@mui/icons-material";
import { ROUTES } from "../constants";
import useADGroupRules from "../hooks/useADGroupRules";
import { ADGroupRuleForm, ADGroupRulesTable } from "../components";
import { useState } from "react";
import { Add as AddIcon } from "@mui/icons-material";
import { ADGroupRuleType } from "../models";

const { AD_GROUP_RULES, IMPORT_AD_GROUPS } = ROUTES;

const ADGroupRules = () => {
  const [showForm, setShowForm] = useState(false);
  const [adGroupRule, setAdGroupRule] = useState<ADGroupRuleType | undefined>(
    undefined
  );

  const { adGroupRules, loading, addRecord, updateRecord } = useADGroupRules();

  const resetForm = () => {
    setShowForm(false);
    setAdGroupRule(undefined);
  };

  const handleAddNewRuleClick = () => {
    setAdGroupRule(undefined);
    setShowForm(true);
  };

  const handleEditRuleClick = (adGroupRule: ADGroupRuleType) => {
    setAdGroupRule(adGroupRule);
    setShowForm(true);
  };

  const handleAddNewRule = async (formFields: ADGroupRuleType) => {
    const success = await addRecord(formFields);
    success && resetForm();
  };

  const handleEditRule = async (formFields: ADGroupRuleType) => {
    const success = await updateRecord(formFields);
    success && resetForm();
  };

  const handleFormSubmit = async (formFields: ADGroupRuleType) => {
    if (Boolean(formFields?.autoAdGroupAssignmentRulesId)) {
      return handleEditRule(formFields);
    }
    return handleAddNewRule(formFields);
  };

  const handleActiveOrDeactiveClick = (rule: ADGroupRuleType) => {
    if (rule.active) {
      return updateRecord({ ...rule, active: false });
    }
    updateRecord({ ...rule, active: true });
  };

  return (
    <Box sx={{ width: "100%", overflow: "auto" }}>
      <Typography variant="h3" style={{ marginBottom: "24px" }}>
        {AD_GROUP_RULES.label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          marginBottom: 3,
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddNewRuleClick}
          sx={{ marginRight: 3 }}
        >
          Add Automatic Entra Group Membership
        </Button>
        <Button startIcon={<LauchIcon />} href={IMPORT_AD_GROUPS.url}>
          Import Entra Group Page
        </Button>
      </Box>
      {showForm && (
        <ADGroupRuleForm
          adGroupRule={adGroupRule}
          onCancel={resetForm}
          onFormSubmit={handleFormSubmit}
        />
      )}
      <ADGroupRulesTable
        loading={loading}
        adGroupRules={adGroupRules}
        onEditClick={handleEditRuleClick}
        onActiveOrDeactiveClick={handleActiveOrDeactiveClick}
      />
    </Box>
  );
};

export default ADGroupRules;
