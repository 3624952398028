import { SaveAlt as SaveAltIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ADGroupForm } from "../components";
import { ADGroupsTable } from "../components/ADGroupsTable";
import { ROUTES } from "../constants";
import { useRegistredADGroups } from "../hooks";
import { ADGroupType } from "../models";

const { IMPORT_AD_GROUPS } = ROUTES;

const ImportADGroups = () => {
  const [showForm, setShowForm] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [groupToBeDeleted, setGroupToBeDeleted] = useState<ADGroupType>();

  const { adGroups, loading, deleteRecord, addRecord } = useRegistredADGroups();

  const handleAddNewGroupClick = () => {
    setShowForm(true);
  };

  const resetForm = () => {
    setShowForm(false);
  };

  const handleDeleteClick = (group: ADGroupType) => {
    setShowDeleteDialog(true);
    setGroupToBeDeleted(group);
  };

  const handleCancelDelete = () => {
    setGroupToBeDeleted(undefined);
    setShowDeleteDialog(false);
  };

  const handleConfirmDelete = async () => {
    deleteRecord(groupToBeDeleted as ADGroupType);
    setShowDeleteDialog(false);
    setGroupToBeDeleted(undefined);
  };

  return (
    <Box sx={{ width: "100%", overflow: "auto" }}>
      <Typography variant="h3" style={{ marginBottom: "24px" }}>
        {IMPORT_AD_GROUPS.label}
      </Typography>
      <Box sx={{ display: "flex", marginBottom: 3 }}>
        <Tooltip title="Add new atutomation rule">
          <Button
            color="primary"
            startIcon={<SaveAltIcon />}
            onClick={handleAddNewGroupClick}
          >
            Import Entra Group
          </Button>
        </Tooltip>
      </Box>
      {showForm && (
        <ADGroupForm onCancel={resetForm} onFormSubmit={addRecord} />
      )}
      <ADGroupsTable
        loading={loading}
        adGroups={adGroups}
        onDeleteClick={handleDeleteClick}
      />
      <Dialog open={showDeleteDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete Group</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this group? This action cannot be
            undone. Click Yes to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ImportADGroups;
