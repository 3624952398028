import APIService from "../api/APIService";
import { ADGroupRuleType } from "../models";

class ADGroupRuleService {
  async getADGroupRules(): Promise<ADGroupRuleType[]> {
    try {
      const groups: ADGroupRuleType[] = await APIService.getADGroupRules();
      return groups;
    } catch (error) {
      console.error("Error fetching ad groups:", error);
      throw error;
    }
  }

  async createADGroupRule(rule: ADGroupRuleType): Promise<ADGroupRuleType> {
    try {
      const newRule: ADGroupRuleType = await APIService.createADGroupRule(rule);
      return newRule;
    } catch (error) {
      console.error("Error creating ad group rule:", error);
      throw error;
    }
  }

  async updateADGroupRule(rule: ADGroupRuleType): Promise<ADGroupRuleType> {
    try {
      const updatedRule: ADGroupRuleType = await APIService.updateADGroupRule(
        rule
      );
      return updatedRule;
    } catch (error) {
      console.error("Error updating ad group rule:", error);
      throw error;
    }
  }

  async getADGroupRuleDetail(ruleId: string): Promise<ADGroupRuleType> {
    try {
      const ruleDetail = await APIService.getADGroupRuleDetail(ruleId);
      return ruleDetail;
    } catch (error) {
      console.error(
        "Error fetching automatic role assignment rule details:",
        error
      );
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ADGroupRuleService();
