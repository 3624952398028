type OrganizationLevelItemType = {
  value: string;
  name: string;
  description: string;
};

export const ORGANIZATION_ASSIGNMENT_LEVEL_TABLE: OrganizationLevelItemType[] =
  [
    {
      value: "0",
      name: "All iHeartMedia",
      description: "Full company assigned.",
    },
    {
      value: "1",
      name: "Division",
      description: "iHeartMedia Division, main branches of iheart org tree.",
    },
    {
      value: "2",
      name: "Level 2",
      description: "Orgs directly under division.",
    },
    {
      value: "3",
      name: "Subsidiary or LOB",
      description:
        "Orgs directly under level 2 (subsidiaries and lines of business).",
    },
    {
      value: "4",
      name: "Consolidated Region",
      description: "Level above region.",
    },
    {
      value: "5",
      name: "Region",
      description: "Region.",
    },
    {
      value: "6",
      name: "Market/Branch/CorporateDept",
      description:
        "Level below region that can be a market, a branch or in corporate a dept.",
    },
    {
      value: "7",
      name: "BU/CostCenter/Station",
      description:
        "Lowest level in the organization can be a BU, cost center, or a station.",
    },
  ];

export const getDescriptionByValue = (value?: string): string => {
  if (!Boolean(value)) {
    return "";
  }
  const valueAsInt = parseInt(value || "0");
  return ORGANIZATION_ASSIGNMENT_LEVEL_TABLE[valueAsInt]?.description || "";
};

export const getNameByValue = (value?: string): string => {
  if (!Boolean(value)) {
    return "";
  }
  const valueAsInt = parseInt(value || "0");
  return ORGANIZATION_ASSIGNMENT_LEVEL_TABLE[valueAsInt]?.name || "";
};

export default ORGANIZATION_ASSIGNMENT_LEVEL_TABLE;
