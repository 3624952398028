import { useEffect, useState } from "react";
import { RoleType } from "../models";
import { RoleService } from "../services";

const useRoles = () => {
  const [roles, setRoles] = useState<RoleType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        setLoading(true);
        const roleList = await RoleService.getRoles();
        setRoles(roleList);
      } catch (error) {
        console.error("Failed to fetch roles", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchRoles();
  }, []);

  return { roles, loading, error };
};

export default useRoles;
