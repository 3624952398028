import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridColumnVisibilityModel,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { useContext, useState } from "react";
import { EmployeesContext } from "../../context";
import { Props } from "./interface";

const RoleAssignmentRulesExclusionTable = ({
  ruleExclusions,
  loading,
  onDeleteRecordClick,
}: Props) => {
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      id: false,
    });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const { employees, loading: employeesLoading } = useContext(EmployeesContext);

  const ActionsColumn: GridColDef = {
    field: "actions",
    headerName: "Actions",
    type: "actions",
    getActions: (params) => {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Edit"
          color="inherit"
          onClick={() => onDeleteRecordClick(params.id as string)}
        />,
      ];
    },
  };

  const EmployeeNameColumn: GridColDef = {
    field: "employeeName",
    headerName: "Employee Name",
    width: 200,
    valueGetter: (_, value) =>
      employees.find((n) => n.employeeID === value.employeeId)?.fullName,
  };

  return (
    <Box sx={{ height: "calc(100vh - 400px)", width: "100%", marginTop: 3 }}>
      <DataGridPremium
        loading={loading || employeesLoading}
        rowSelection={false}
        rows={ruleExclusions || []}
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { quickFilterProps: { debounceMs: 300 } } }}
        autosizeOnMount
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
        columns={[
          ActionsColumn,
          {
            field: "id",
            headerName: "ID",
          },
          {
            field: "notes",
            headerName: "Notes",
            width: 250,
          },
          EmployeeNameColumn,
          { field: "employeeId", headerName: "Employee ID" },
        ]}
      />
    </Box>
  );
};

export default RoleAssignmentRulesExclusionTable;
