import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { EmployeeReachFormErrorType, EmployeeReachFormType } from "./interface";
import { BLANK_ERROR_MESSAGE, TEXT_FIELD_STYLES } from "../../constants";
import { useContext, useState } from "react";
import { OrgHierarchyTabularType, RoleType } from "../../models";
import { SimplifiedRoleAssignmentContext } from "../../context";
import validateEmployeeReachForm from "./utils";

const GRID_SIZE_PROPS = { xs: 12, md: 12, lg: 6 };

const EMPTY_FORM_VALUE: EmployeeReachFormType = {
  roles: [],
  nodes: [],
};

const EmployeeReachForm = () => {
  const [formFields, setFormFields] =
    useState<EmployeeReachFormType>(EMPTY_FORM_VALUE);
  const [formError, setFormError] = useState<EmployeeReachFormErrorType>();

  const {
    orgNodes,
    roles,
    loading,
    selectedEmployee,
    setShowForm,
    setAssignmentsToBeCreatedAndCounts,
  } = useContext(SimplifiedRoleAssignmentContext);

  const onFormChange = (
    key: string,
    value: RoleType[] | OrgHierarchyTabularType[]
  ) => {
    setFormFields((prev) => ({ ...prev, [key]: value }));
  };

  const handleFormSubmitClick = () => {
    const errors = validateEmployeeReachForm(formFields);
    if (Object.keys(errors).length) {
      return setFormError(errors);
    }
    setAssignmentsToBeCreatedAndCounts(formFields.roles, formFields.nodes);
  };

  return (
    <Box
      sx={{
        padding: 1,
        borderRadius: 2,
        border: "1px solid lightgray",
        textAlign: "left",
        marginBottom: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AddIcon sx={{ marginRight: 0.5 }} />
          <Typography variant="h6" component="h4">
            {`Add Reach to ${selectedEmployee?.fullNameWithID}`}
          </Typography>
        </Box>
        <IconButton color="error" onClick={() => setShowForm(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item {...GRID_SIZE_PROPS}>
          <Autocomplete
            multiple
            loading={loading}
            options={roles}
            getOptionLabel={(option) => option.roleDescriptionWithID || ""}
            onChange={(_, value) => onFormChange("roles", value)}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Role"
                variant="outlined"
                sx={TEXT_FIELD_STYLES}
                error={Boolean(formError?.roles)}
                helperText={formError?.roles || BLANK_ERROR_MESSAGE}
              />
            )}
          />
        </Grid>

        <Grid item {...GRID_SIZE_PROPS}>
          <Autocomplete
            multiple
            loading={loading}
            options={orgNodes}
            getOptionLabel={(option) =>
              option.reverseFullyQualifiedNodeDescription || ""
            }
            onChange={(_, value) => onFormChange("nodes", value)}
            filterSelectedOptions
            getOptionKey={(option) =>
              `${option.nodeID} ${option.fccID} ${option.stationType}`
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Nodes"
                variant="outlined"
                sx={TEXT_FIELD_STYLES}
                error={Boolean(formError?.nodes)}
                helperText={formError?.nodes || BLANK_ERROR_MESSAGE}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 4,
          alignItems: "center",
        }}
      >
        <Button
          size="large"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleFormSubmitClick}
        >
          Add Reach
        </Button>
      </Box>
    </Box>
  );
};

export default EmployeeReachForm;
