import {
  Warning as WarningIcon,
  Report as ReportIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useContext } from "react";
import { SimplifiedRoleAssignmentContext } from "../../context";

const SimplifiedAssignmentCreationModal = () => {
  const {
    assignmentsToBeCreated,
    counts,
    selectedEmployee,
    processingStatus,
    processAndCreateAssignments,
    resetAssignmentsToBeCreated,
    setAssignmentsToBeCreatedAndCounts,
  } = useContext(SimplifiedRoleAssignmentContext);

  const StatusColumn: GridColDef = {
    field: "status",
    headerName: "Status",
    renderCell: (params) => {
      const getStatusIcon = () => {
        switch (params.row.status) {
          case "Loading":
            return <CircularProgress size={24} />;
          case "Success":
            return <CheckIcon color="success" />;
          case "Error":
            return <CloseIcon color="error" />;
          default:
            return <></>;
        }
      };
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {getStatusIcon()}
        </Box>
      );
    },
  };

  const ErrorColumn: GridColDef = {
    field: "errorMessage",
    headerName: "Error Message",
    width: 300,
  };

  const baseColumns = [
    {
      field: "roleName",
      headerName: "Role Name",
    },
    {
      field: "roleDescription",
      headerName: "Role Description",
      width: 150,
    },
    {
      field: "nodeName",
      headerName: "Node Name",
      width: 200,
    },
    {
      field: "reverseFullyQualifiedNodeDescription",
      headerName: "Reverse Fully Qualified Node Description",
      width: 500,
    },
  ];

  const hasError = assignmentsToBeCreated.find(
    (assignment) => assignment.errorMessage
  );

  return (
    <Dialog open={true} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Typography variant="h6" component="h3">
          {processingStatus === "Pending" &&
            "Confirm Role Assignments Creation"}
          {processingStatus === "Processing" && "Processing Role Assignments"}
          {processingStatus === "Done" && "Role Assignments Processed"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {processingStatus === "Pending" && (
          <Box
            sx={{
              marginBottom: 2,
              borderRadius: 1,
              padding: 1,
              backgroundColor: "warning.light",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <WarningIcon sx={{ marginRight: 2 }} />
            <Typography variant="body1">
              You have selected {counts.rolesCount} different roles and{" "}
              {counts.nodesCount} different nodes. Confirming the creation will
              result in {assignmentsToBeCreated.length} role assignments being
              created. Please confirm that you really want to create each of the
              records listed below for the employee{" "}
              {selectedEmployee?.fullNameWithID}.
            </Typography>
          </Box>
        )}
        {processingStatus === "Processing" && (
          <Box
            sx={{
              marginBottom: 2,
              borderRadius: 1,
              padding: 1,
              backgroundColor: "error.light",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ReportIcon sx={{ marginRight: 2 }} />
            <Typography variant="body1">
              If you leave this page or refresh it, you will interrupt the
              creation process of the role assignments bellow, which may result
              in errors and inconsistencies in permission and data. The status
              column of this table will be updated as these role assignments are
              processed and created. Please wait for the process to complete.
            </Typography>
          </Box>
        )}
        {processingStatus === "Done" && (
          <Box
            sx={{
              marginBottom: 2,
              borderRadius: 1,
              padding: 1,
              backgroundColor: "success.light",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CheckIcon sx={{ marginRight: 2 }} />
            <Typography variant="body1">
              All role assignments have been processed. The ones that were
              created successfully are marked with a 'Check,' while those that
              encountered an error are marked with an 'X.' If any error occurred
              during this process, you can view the reason for each error in the
              'Error Message' column and retry them later. It is safe to close
              this dialog now.
            </Typography>
          </Box>
        )}
        <DataGridPremium
          rowSelection={false}
          rows={assignmentsToBeCreated || []}
          autosizeOnMount
          getRowId={(row) => `${row.roleID} ${row.nodeID}` || 0}
          hideFooter
          disableColumnSorting
          disableColumnMenu
          columns={
            processingStatus === "Pending"
              ? baseColumns
              : processingStatus === "Done" && hasError
              ? [StatusColumn, ErrorColumn, ...baseColumns]
              : [StatusColumn, ...baseColumns]
          }
        />
      </DialogContent>
      <DialogActions>
        {processingStatus === "Pending" ? (
          <>
            <Button
              sx={{ marginRight: 2 }}
              onClick={() => setAssignmentsToBeCreatedAndCounts([], [])}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => processAndCreateAssignments()}
            >
              Confirm Creation
            </Button>
          </>
        ) : (
          <Button
            disabled={processingStatus === "Processing"}
            onClick={() => {
              resetAssignmentsToBeCreated();
            }}
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SimplifiedAssignmentCreationModal;
