import APIService from "../api/APIService";
import { AutoRoleAssignmentRuleType } from "../models";

class AutomaticRoleAssignmentRuleService {
  async getAutomaticRoleAssignmentRules(): Promise<
    AutoRoleAssignmentRuleType[]
  > {
    try {
      const automationRules: AutoRoleAssignmentRuleType[] =
        await APIService.getAutomaticRoleAssignmentRules();
      return automationRules;
    } catch (error) {
      console.error("Error fetching automatic role assignment rules:", error);
      throw error;
    }
  }

  async createAutomaticRoleAssignmentRule(
    automaticRule: AutoRoleAssignmentRuleType
  ): Promise<AutoRoleAssignmentRuleType> {
    try {
      const newRule: AutoRoleAssignmentRuleType =
        await APIService.createAutomaticRoleAssingmentRule(automaticRule);
      return newRule;
    } catch (error) {
      console.error(
        "Error creating new automatic role assignment rules:",
        error
      );
      throw error;
    }
  }

  async updateAutomaticRoleAssignmentRule(
    automaticRule: AutoRoleAssignmentRuleType
  ): Promise<AutoRoleAssignmentRuleType> {
    try {
      const newRuleVersion: AutoRoleAssignmentRuleType =
        await APIService.updateAutomaticRoleAssignmentRule(automaticRule);
      return newRuleVersion;
    } catch (error) {
      console.error("Error updating automatic role assignment rule:", error);
      throw error;
    }
  }

  async getAutomaticRoleAssignmentRuleDetail(
    ruleId: string
  ): Promise<AutoRoleAssignmentRuleType> {
    try {
      const ruleDetail = await APIService.getAutomaticRoleAssignmentRuleDetail(
        ruleId
      );
      return ruleDetail;
    } catch (error) {
      console.error(
        "Error fetching automatic role assignment rule details:",
        error
      );
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AutomaticRoleAssignmentRuleService();
