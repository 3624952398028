import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import './index.css';
import App from './App';
import { pca } from './auth';
// import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </MsalProvider>
  </React.StrictMode>
);


// reportWebVitals();
