import { EmployeeReachFormErrorType, EmployeeReachFormType } from "./interface";
import { startCase } from "lodash";

const validateEmployeeReachForm = (form: EmployeeReachFormType) => {
  const errors: EmployeeReachFormErrorType = {};

  const requiredFields: (keyof EmployeeReachFormType)[] = ["roles", "nodes"];

  requiredFields.forEach((field) => {
    if (!form[field].length) {
      errors[field] = `${startCase(field)} are required`;
    }
  });

  return errors;
};

export default validateEmployeeReachForm;
