import { useCallback, useEffect, useState } from "react";
import { ADGroupType, ADGroupAPIErrorType } from "../models";
import { ADGroupService } from "../services";
import { enqueueSnackbar } from "notistack";

const CREATE_RECORD_SUCCESS_MESSAGE = "AD Group imported successfully";
const DELETE_RECORD_SUCCESS_MESSAGE = "AD Group deleted successfully";
const BASE_ERROR_MESSAGE = "Error with AD Group: ";
const FETCH_ERROR_MESSAGE = "Failed to fetch ad groups";

const useRegistredADGroups = () => {
  const [adGroups, setADGroups] = useState<ADGroupType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchADGroups = useCallback(async () => {
    try {
      setLoading(true);
      const regGroups = await ADGroupService.getADGroups();
      setADGroups(regGroups);
    } catch (error) {
      console.error(FETCH_ERROR_MESSAGE, error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const importADGroup = useCallback(
    async (group: ADGroupType): Promise<boolean> => {
      try {
        setLoading(true);
        await ADGroupService.importADGroup(group);
        enqueueSnackbar(CREATE_RECORD_SUCCESS_MESSAGE, {
          variant: "success",
        });
        fetchADGroups();
        return true;
      } catch (error) {
        setError(error);
        enqueueSnackbar(
          BASE_ERROR_MESSAGE +
            (error as ADGroupAPIErrorType)?.response?.data?.detail,
          {
            variant: "error",
          }
        );
        setLoading(false);
        return false;
      }
    },
    [fetchADGroups]
  );

  const deleteADGroup = useCallback(
    async (group: ADGroupType): Promise<boolean> => {
      try {
        setLoading(true);
        await ADGroupService.deleteADGroup(group);
        enqueueSnackbar(DELETE_RECORD_SUCCESS_MESSAGE, {
          variant: "success",
        });
        fetchADGroups();
        return true;
      } catch (error) {
        setError(error);
        enqueueSnackbar(
          BASE_ERROR_MESSAGE +
            (error as ADGroupAPIErrorType)?.response?.data?.detail,
          {
            variant: "error",
          }
        );
        setLoading(false);
        return false;
      }
    },
    [fetchADGroups]
  );

  useEffect(() => {
    fetchADGroups();
  }, [fetchADGroups]);

  return {
    adGroups,
    loading,
    error,
    addRecord: importADGroup,
    deleteRecord: deleteADGroup,
  };
};

export default useRegistredADGroups;
