import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { useState } from "react";
import { Props } from "./interface";

const ADGroupsTable = ({ loading, adGroups, onDeleteClick }: Props) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const ActionsColumn: GridColDef = {
    field: "actions",
    headerName: "Actions",
    type: "actions",
    getActions: (params) => {
      return [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Edit"
          onClick={() => onDeleteClick(params.row)}
          color="inherit"
        />,
      ];
    },
  };

  return (
    <Box sx={{ height: "calc(100vh - 216px)", width: "100%", marginTop: 3 }}>
      <DataGridPremium
        loading={loading}
        rowSelection={false}
        rows={adGroups || []}
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { quickFilterProps: { debounceMs: 300 } } }}
        autosizeOnMount
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
        getRowId={(row) => row.adGroupRegistrationId}
        columns={[
          ActionsColumn,
          {
            field: "adGroupRegistrationId",
            headerName: "ID",
            width: 50,
          },
          {
            field: "groupName",
            headerName: "Name",
            width: 250,
          },
          {
            field: "description",
            headerName: "Description",
            width: 150,
          },
          {
            field: "entraObjectId",
            headerName: "Entra Object ID",
            width: 200,
          },
          {
            field: "registrationNotes",
            headerName: "Registration Notes",
            width: 150,
          },
        ]}
      />
    </Box>
  );
};

export default ADGroupsTable;
