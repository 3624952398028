import { useCallback, useEffect, useState } from "react";
import { ADGroupRuleType, ADGroupRuleAPIErrorType } from "../models";
import ADGroupRulesService from "../services/ADGroupRuleService";
import { enqueueSnackbar } from "notistack";

const FETCH_ERROR_MESSAGE = "Failed to fetch ad group rules";
const BASE_ERROR_MESSAGE = "Error with AD Group Rule: ";
const CREATE_RECORD_SUCCESS_MESSAGE = "AD Group Rule created successfully";
const UPDATE_RECORD_SUCCESS_MESSAGE = "AD Group Rule updated successfully";

const useADGroupRules = () => {
  const [adGroupRules, setADGroupRules] = useState<ADGroupRuleType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchADGroupRules = useCallback(async () => {
    try {
      setLoading(true);
      const adRules = await ADGroupRulesService.getADGroupRules();
      setADGroupRules(adRules);
    } catch (error) {
      console.error(FETCH_ERROR_MESSAGE, error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const createADGroupRule = useCallback(
    async (rule: ADGroupRuleType): Promise<boolean> => {
      try {
        setLoading(true);
        await ADGroupRulesService.createADGroupRule(rule);
        enqueueSnackbar(CREATE_RECORD_SUCCESS_MESSAGE, {
          variant: "success",
        });
        fetchADGroupRules();
        return true;
      } catch (error) {
        setError(error);
        enqueueSnackbar(
          BASE_ERROR_MESSAGE +
            (error as ADGroupRuleAPIErrorType)?.response?.data?.detail,
          {
            variant: "error",
          }
        );
        setLoading(false);
        return false;
      }
    },
    [fetchADGroupRules]
  );

  const updateADGroupRule = useCallback(
    async (rule: ADGroupRuleType): Promise<boolean> => {
      try {
        setLoading(true);
        await ADGroupRulesService.updateADGroupRule(rule);
        enqueueSnackbar(UPDATE_RECORD_SUCCESS_MESSAGE, {
          variant: "success",
        });
        fetchADGroupRules();
        return true;
      } catch (error) {
        setError(error);
        enqueueSnackbar(
          BASE_ERROR_MESSAGE +
            (error as ADGroupRuleAPIErrorType)?.response?.data?.detail,
          {
            variant: "error",
          }
        );
        setLoading(false);
        return false;
      }
    },
    [fetchADGroupRules]
  );

  useEffect(() => {
    fetchADGroupRules();
  }, [fetchADGroupRules]);

  return {
    adGroupRules,
    loading,
    error,
    addRecord: createADGroupRule,
    updateRecord: updateADGroupRule,
  };
};

export default useADGroupRules;
