import { ADGroupType } from "../../models";
import { startCase } from "lodash";
import { ADGroupFormErrorType } from "./interface";

export const validateADGroupForm = (form: ADGroupType) => {
  const errors: ADGroupFormErrorType = {};

  const requiredFields: (keyof ADGroupFormErrorType)[] = ["entraObjectId"];

  requiredFields.forEach((field) => {
    if (!form[field]) {
      errors[field] = `${startCase(field)} is required`;
    }
  });

  return errors;
};
