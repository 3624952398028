import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import {
  AutomaticRoleAssignmentRuleExclusionAPIErrorType,
  AutomaticRoleAssignmentRuleExclusionType,
} from "../models";
import { AutomaticRoleAssignmentRuleExclusionService } from "../services";

const BASE_ERROR_MESSAGE = "Error with Role Assignment Exclusion: ";
const FETCH_ERROR_MESSAGE = "Something went wrong. Please try again later.";
const CREATE_RECORD_SUCCESS_MESSAGE =
  "Automated Role Assignment Rule Exclusion created successfully";
const DELETE_RECORD_SUCCESS_MESSAGE =
  "Automated Role Assignment Rule Exclusion deleted successfully";

const useAutomationRuleExclusions = (ruleId: string) => {
  const [ruleExclusions, setRuleExclusions] = useState<
    AutomaticRoleAssignmentRuleExclusionType[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchAutomationRuleExclusions = useCallback(async () => {
    try {
      setLoading(true);
      const exclusions =
        await AutomaticRoleAssignmentRuleExclusionService.getAutomaticRoleAssignmentRuleExclusions(
          ruleId
        );
      setRuleExclusions(exclusions);
    } catch (error) {
      setError(error);
      enqueueSnackbar(FETCH_ERROR_MESSAGE, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [ruleId]);

  const createAutomationRuleExclusion = useCallback(
    async (ruleExclusion: AutomaticRoleAssignmentRuleExclusionType) => {
      try {
        setLoading(true);
        await AutomaticRoleAssignmentRuleExclusionService.createAutomaticRoleAssignmentRuleExclusion(
          ruleExclusion
        );
        enqueueSnackbar(CREATE_RECORD_SUCCESS_MESSAGE, {
          variant: "success",
        });
        fetchAutomationRuleExclusions();
      } catch (error) {
        console.error("Failed to create Role Assignment Exclusion:", error);
        setError(error);
        enqueueSnackbar(
          BASE_ERROR_MESSAGE +
            (error as AutomaticRoleAssignmentRuleExclusionAPIErrorType)
              ?.response?.data?.detail,
          {
            variant: "error",
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [fetchAutomationRuleExclusions]
  );
  const deleteAutomationRuleExclusion = useCallback(
    async (exclusionId: string) => {
      try {
        setLoading(true);
        await AutomaticRoleAssignmentRuleExclusionService.deleteAutomaticRoleAssignmentRuleExclusion(
          exclusionId
        );
        enqueueSnackbar(DELETE_RECORD_SUCCESS_MESSAGE, {
          variant: "success",
        });
        fetchAutomationRuleExclusions();
      } catch (error) {
        console.error("Failed to delete Role Assignment Exclusion:", error);
        setError(error);
        setLoading(false);
        enqueueSnackbar(
          BASE_ERROR_MESSAGE +
            (error as AutomaticRoleAssignmentRuleExclusionAPIErrorType)
              ?.response?.data?.detail,
          {
            variant: "error",
          }
        );
      }
    },
    [fetchAutomationRuleExclusions]
  );

  useEffect(() => {
    fetchAutomationRuleExclusions();
  }, [fetchAutomationRuleExclusions]);

  return {
    ruleExclusions,
    loading,
    error,
    addRecord: createAutomationRuleExclusion,
    deleteRecord: deleteAutomationRuleExclusion,
  };
};

export default useAutomationRuleExclusions;
