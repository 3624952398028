import { createContext, ReactNode, useState } from "react";
import {
  useEmployeeAssignments,
  useEmployees,
  useOrgNodes,
  useRoles,
} from "../hooks";
import {
  EmployeeType,
  OrgHierarchyTabularType,
  RoleAssignmentType,
  RoleType,
  SimplifiedAssignmentType,
} from "../models";
import { enqueueSnackbar } from "notistack";

type AssignmentsProcessingStatus = "Pending" | "Processing" | "Done";

type SimplifiedRoleAssignmentContextType = {
  orgNodes: OrgHierarchyTabularType[];
  roles: RoleType[];
  employees: EmployeeType[];
  loading: boolean;
  error: unknown | null;

  selectedEmployee: EmployeeType | null;
  setSelectedEmployee: (employee: EmployeeType | null) => void;

  showForm: boolean;
  setShowForm: (show: boolean) => void;

  employeeAssignments: RoleAssignmentType[];
  loadingEmployeeAssignments: boolean;

  processingStatus: AssignmentsProcessingStatus;
  assignmentsToBeCreated: SimplifiedAssignmentType[];
  counts: { rolesCount: number; nodesCount: number };
  processAndCreateAssignments: VoidFunction;

  deleteAssignment: (assignmentID: string) => void;

  setAssignmentsToBeCreatedAndCounts: (
    roles: RoleType[],
    nodes: OrgHierarchyTabularType[]
  ) => void;
  resetAssignmentsToBeCreated: VoidFunction;
};

const SimplifiedRoleAssignmentContext =
  createContext<SimplifiedRoleAssignmentContextType>({
    orgNodes: [],
    roles: [],
    employees: [],
    loading: false,
    error: null,

    selectedEmployee: null,
    setSelectedEmployee: () => {},

    showForm: false,
    setShowForm: () => {},

    employeeAssignments: [],
    loadingEmployeeAssignments: false,

    processingStatus: "Pending",
    assignmentsToBeCreated: [],
    counts: { rolesCount: 0, nodesCount: 0 },
    processAndCreateAssignments: () => {},

    deleteAssignment: () => {},

    setAssignmentsToBeCreatedAndCounts: () => {},
    resetAssignmentsToBeCreated: () => {},
  });

const MAX_ASSIGNMENTS_PER_BATCH =
  Number(process.env.REACT_APP_MAX_ASSIGNMENTS_PER_BATCH) || 25;

const ASSIGNMENTS_CREATION_SUCCESS_MESSAGE =
  "All role assignments created successfully";
const ASSIGNMENTS_CREATION_ERROR_MESSAGE =
  "There was an error creating the role assignments, please check the reason on the error message column";

export const SimplifiedRoleAssignmentProvider = (props: {
  children: ReactNode;
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeType | null>(
    null
  );
  const [showForm, setShowForm] = useState(false);
  const [assignmentsToBeCreated, setAssignmentsToBeCreated] = useState<
    SimplifiedAssignmentType[]
  >([]);
  const [counts, setCounts] = useState({ rolesCount: 0, nodesCount: 0 });
  const [processingStatus, setProcessingStatus] =
    useState<AssignmentsProcessingStatus>("Pending");

  const {
    orgNodes,
    loading: orgNodesLoading,
    error: orgNodesError,
  } = useOrgNodes();
  const { roles, loading: rolesLoading, error: rolesError } = useRoles();
  const {
    employees,
    loading: employeesLoading,
    error: employeesError,
  } = useEmployees();
  const {
    employeeAssignments,
    loading: employeeAssignmentsLoading,
    refetch,
    addRecord,
    deleteRecord,
  } = useEmployeeAssignments(selectedEmployee?.employeeID.toString() || "");

  const updateAssignmentStatus = (
    assignmentIndex: number,
    status: SimplifiedAssignmentType["status"],
    errorMesssage?: string
  ) => {
    setAssignmentsToBeCreated((prev) => {
      const updatedAssignments = [...prev];
      updatedAssignments[assignmentIndex] = {
        ...updatedAssignments[assignmentIndex],
        status,
        errorMessage: errorMesssage,
      };
      return updatedAssignments;
    });
  };

  const createSingleAssignmentAndReturnError = async (
    index: number,
    assignment: SimplifiedAssignmentType
  ): Promise<boolean> => {
    try {
      updateAssignmentStatus(index, "Loading");
      await addRecord(assignment);
      updateAssignmentStatus(index, "Success");
      return false;
    } catch (error) {
      updateAssignmentStatus(
        index,
        "Error",
        (error as any)?.response?.data?.detail || (error as any)?.message
      );
      return true;
    }
  };

  const createAssignmentBatchAndReturnError = async (
    initialIndex: number,
    assignments: SimplifiedAssignmentType[]
  ): Promise<boolean> => {
    const errorArray = await Promise.all(
      assignments.map(async (assignment, index) => {
        const hasError = await createSingleAssignmentAndReturnError(
          initialIndex + index,
          assignment
        );
        return hasError;
      })
    );
    return errorArray.reduce((acc, curr) => acc || curr, false);
  };

  const processAndCreateAssignments = async () => {
    setProcessingStatus("Processing");
    const assignmentsCount = assignmentsToBeCreated.length;
    let hasError = false;
    let index = 0;

    while (index < assignmentsCount) {
      const batch = assignmentsToBeCreated.slice(
        index,
        Math.min(index + MAX_ASSIGNMENTS_PER_BATCH, assignmentsCount)
      );
      hasError =
        (await createAssignmentBatchAndReturnError(index, batch)) || hasError;
      index += MAX_ASSIGNMENTS_PER_BATCH;
    }

    refetch();
    setProcessingStatus("Done");
    if (!hasError) {
      setShowForm(false);
      enqueueSnackbar(ASSIGNMENTS_CREATION_SUCCESS_MESSAGE, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(ASSIGNMENTS_CREATION_ERROR_MESSAGE, { variant: "error" });
    }
  };

  const setAssignmentsToBeCreatedAndCounts = (
    roles: RoleType[],
    nodes: OrgHierarchyTabularType[]
  ) => {
    const assignments: SimplifiedAssignmentType[] = [];
    roles.forEach((role) => {
      nodes.forEach((node) => {
        assignments.push({
          roleID: role.roleID,
          roleName: role.roleName,
          roleDescription: role.roleDescription || "",
          nodeID: node.nodeID,
          nodeName: node.nodeName || "",
          reverseFullyQualifiedNodeDescription:
            node.reverseFullyQualifiedNodeDescription || "",
          assignmentType: 2,
          assignmentStatus: "Assigned",
        });
      });
    });
    setAssignmentsToBeCreated(assignments);
    setCounts({ rolesCount: roles.length, nodesCount: nodes.length });
  };

  const resetAssignmentsToBeCreated = () => {
    setAssignmentsToBeCreated([]);
    setCounts({ rolesCount: 0, nodesCount: 0 });
    setProcessingStatus("Pending");
  };

  const deleteAssignment = async (assignmentId: string) => {
    try {
      await deleteRecord(assignmentId);
    } catch (error) {}
  };

  return (
    <SimplifiedRoleAssignmentContext.Provider
      value={{
        orgNodes,
        roles,
        employees,
        loading: orgNodesLoading || rolesLoading || employeesLoading,
        error: orgNodesError || rolesError || employeesError,

        selectedEmployee,
        setSelectedEmployee,

        showForm,
        setShowForm,

        employeeAssignments,
        loadingEmployeeAssignments: employeeAssignmentsLoading,

        processingStatus,
        assignmentsToBeCreated,
        counts,
        processAndCreateAssignments,

        deleteAssignment,

        setAssignmentsToBeCreatedAndCounts,
        resetAssignmentsToBeCreated,
      }}
    >
      {props.children}
    </SimplifiedRoleAssignmentContext.Provider>
  );
};

export default SimplifiedRoleAssignmentContext;
