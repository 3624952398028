import APIService from "../api/APIService";
import { OrgHierarchyTabularType } from "../models";

class OrganizationalNodeService {
  async getOrganizationalNodes(): Promise<OrgHierarchyTabularType[]> {
    try {
      const nodes: OrgHierarchyTabularType[] =
        await APIService.getOrgHierarchy();
      return nodes;
    } catch (error) {
      console.error(
        "Error fetching fully enriched organizational nodes:",
        error
      );
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new OrganizationalNodeService();
