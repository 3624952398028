import { AutoRoleAssignmentRuleType } from "../../models";
import { RuleFormErrorType } from "./interface";
import { startCase } from "lodash";

export const validateAutomationRuleForm = (
  form: AutoRoleAssignmentRuleType
) => {
  const errors: RuleFormErrorType = {};

  const requiredFields: (keyof RuleFormErrorType)[] = [
    "shortDescription",
    "jobCodeId",
    "roleId",
    "organizationLevel",
  ];

  requiredFields.forEach((field) => {
    if (!form[field]) {
      errors[field] = `${startCase(field)} is required`;
    }
  });

  return errors;
};

export default validateAutomationRuleForm;
