type Route = {
  label: string;
  url: string;
};

type RouteTypes =
  | "HOME"
  | "ROLE_ASSIGNMENT"
  | "SIMPLIFIED_ROLE_ASSIGNMENT"
  | "ROLES"
  | "ROLE_ASSIGNMENT_RULES"
  | "ROLE_ASSIGNMENT_EXCLUSIONS"
  | "AD_GROUP_RULES"
  | "AD_GROUP_EXCLUSIONS"
  | "IMPORT_AD_GROUPS";

const AD_GROUP_RULES_BASE_URL = "/adGroupRules";
const ROLE_ASSIGNMENT_RULES_BASE_URL = "/roleRules";

const ROUTES: Record<RouteTypes, Route> = {
  HOME: {
    label: "Home",
    url: "/",
  },
  ROLE_ASSIGNMENT: {
    label: "Role Assignment",
    url: "/roleAssignmentGrid",
  },
  SIMPLIFIED_ROLE_ASSIGNMENT: {
    label: "Simplified Role Assignment",
    url: "/simplifiedRoleAssignment",
  },
  ROLES: {
    label: "Roles",
    url: "/rolesGrid",
  },
  ROLE_ASSIGNMENT_RULES: {
    label: "Automatic Role Assignment",
    url: ROLE_ASSIGNMENT_RULES_BASE_URL,
  },
  ROLE_ASSIGNMENT_EXCLUSIONS: {
    label: "Automatic Role Assignment Exclusions",
    url: ROLE_ASSIGNMENT_RULES_BASE_URL + "/exclusions/:ruleId",
  },
  AD_GROUP_RULES: {
    label: "Automatic Entra Group Memberships",
    url: AD_GROUP_RULES_BASE_URL,
  },
  AD_GROUP_EXCLUSIONS: {
    label: "Automatic Entra Group Membership Exclusions",
    url: AD_GROUP_RULES_BASE_URL + "/exclusions/:ruleId",
  },
  IMPORT_AD_GROUPS: {
    label: "Import Entra Groups",
    url: AD_GROUP_RULES_BASE_URL + "/importADGroups",
  },
};

export const isRouteOnADGroupSubdomain = (route: string) => {
  return [ROUTES.AD_GROUP_RULES.url, ROUTES.IMPORT_AD_GROUPS.url].includes(
    route
  );
};

export default ROUTES;
