import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import {
  EmployeeAssignmentsTable,
  EmployeeReachForm,
  SimplifiedAssignmentCreationModal,
  SimplifiedAssignmentHeader,
} from "../components";
import {
  SimplifiedRoleAssignmentContext,
  SimplifiedRoleAssignmentProvider,
} from "../context";

const BaseSimplifiedRoleAssignment = () => {
  const { selectedEmployee, showForm, assignmentsToBeCreated } = useContext(
    SimplifiedRoleAssignmentContext
  );

  const hasEmployee = !!selectedEmployee;
  const hasAssignmentsToBeCreated = !!assignmentsToBeCreated.length;

  return (
    <Box sx={{ width: "100%", overflow: "auto" }}>
      <Typography variant="h3" style={{ marginBottom: "24px" }}>
        Simplified Role Assignment
      </Typography>
      <SimplifiedAssignmentHeader />
      {showForm && hasEmployee && <EmployeeReachForm />}
      {hasEmployee && <EmployeeAssignmentsTable />}
      {hasAssignmentsToBeCreated && hasEmployee && (
        <SimplifiedAssignmentCreationModal />
      )}
    </Box>
  );
};

const SimplifiedRoleAssignment = () => {
  return (
    <SimplifiedRoleAssignmentProvider>
      <BaseSimplifiedRoleAssignment />
    </SimplifiedRoleAssignmentProvider>
  );
};

export default SimplifiedRoleAssignment;
