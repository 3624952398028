import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import {
  ADGroupRuleExclusionAPIErrorType,
  ADGroupRuleExclusionType,
} from "../models";
import { ADGroupRuleExclusionService } from "../services";

const BASE_ERROR_MESSAGE = "Error with Entra Group Membership Exclusion: ";
const FETCH_ERROR_MESSAGE = "Something went wrong. Please try again later.";
const CREATE_RECORD_SUCCESS_MESSAGE =
  "Entra Group Membership Exclusion created successfully";
const DELETE_RECORD_SUCCESS_MESSAGE =
  "Entra Group Membership Exclusion deleted successfully";

const useADGroupRuleExclusions = (ruleId: string) => {
  const [ruleExclusions, setRuleExclusions] = useState<
    ADGroupRuleExclusionType[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchADGroupRuleExclusions = useCallback(async () => {
    try {
      setLoading(true);
      const exclusions =
        await ADGroupRuleExclusionService.getADGroupRuleExclusions(ruleId);
      setRuleExclusions(exclusions);
    } catch (error) {
      setError(error);
      enqueueSnackbar(FETCH_ERROR_MESSAGE, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [ruleId]);

  const createADGroupRuleExclusion = useCallback(
    async (ruleExclusion: ADGroupRuleExclusionType) => {
      try {
        setLoading(true);
        await ADGroupRuleExclusionService.createADGroupRuleExclusion(
          ruleExclusion
        );
        enqueueSnackbar(CREATE_RECORD_SUCCESS_MESSAGE, {
          variant: "success",
        });
        fetchADGroupRuleExclusions();
      } catch (error) {
        console.error(FETCH_ERROR_MESSAGE, error);
        setError(error);
        enqueueSnackbar(
          BASE_ERROR_MESSAGE +
            (error as ADGroupRuleExclusionAPIErrorType)?.response?.data?.detail,
          {
            variant: "error",
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [fetchADGroupRuleExclusions]
  );
  const deleteADGroupRuleExclusion = useCallback(
    async (exclusionId: string) => {
      try {
        setLoading(true);
        await ADGroupRuleExclusionService.deleteADGroupRuleExclusion(
          exclusionId
        );
        enqueueSnackbar(DELETE_RECORD_SUCCESS_MESSAGE, {
          variant: "success",
        });
        fetchADGroupRuleExclusions();
      } catch (error) {
        console.error(FETCH_ERROR_MESSAGE, error);
        setError(error);
        setLoading(false);
        enqueueSnackbar(
          BASE_ERROR_MESSAGE +
            (error as ADGroupRuleExclusionAPIErrorType)?.response?.data?.detail,
          {
            variant: "error",
          }
        );
      }
    },
    [fetchADGroupRuleExclusions]
  );

  useEffect(() => {
    fetchADGroupRuleExclusions();
  }, [fetchADGroupRuleExclusions]);

  return {
    ruleExclusions,
    loading,
    error,
    addRecord: createADGroupRuleExclusion,
    deleteRecord: deleteADGroupRuleExclusion,
  };
};

export default useADGroupRuleExclusions;
