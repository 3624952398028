import { useCallback, useEffect, useState } from "react";
import { RoleAssignmentType, SimplifiedAssignmentType } from "../models";
import { EmployeeAssignmentService } from "../services";
import { enqueueSnackbar } from "notistack";

const FETCH_ERROR_MESSAGE = "Failed to fetch employee role assignments";
const DELETE_RECORD_SUCCESS_MESSAGE =
  "Employee role assignment deleted successfully";

const NO_ASSIGNMENTS_ERROR_STATUS = 404;
const NO_ASSIGNMENTS_ERROR_MESSAGE =
  "No role assignments found for this employee";

const useEmployeeAssignments = (employeeId: string) => {
  const [employeeAssignments, setEmployeeAssignments] = useState<
    RoleAssignmentType[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchEmployeeAssignments = useCallback(async () => {
    if (!employeeId) {
      return;
    }

    try {
      setLoading(true);
      const assignments =
        await EmployeeAssignmentService.getEmployeeRoleAssignments(employeeId);
      setEmployeeAssignments(assignments);
    } catch (error) {
      console.error(FETCH_ERROR_MESSAGE, error);
      enqueueSnackbar(
        (error as any).response?.status === NO_ASSIGNMENTS_ERROR_STATUS
          ? NO_ASSIGNMENTS_ERROR_MESSAGE
          : FETCH_ERROR_MESSAGE,
        {
          variant: "error",
        }
      );
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [employeeId]);

  const createEmployeeAssignment = useCallback(
    async (simplifiedAssignment: SimplifiedAssignmentType) => {
      if (!employeeId) {
        return;
      }

      try {
        setLoading(true);
        await EmployeeAssignmentService.createEmployeeAssignment(
          employeeId,
          simplifiedAssignment
        );
      } catch (error) {
        console.error(FETCH_ERROR_MESSAGE, error);
        setError(error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [employeeId]
  );

  const deleteEmployeeAssignment = useCallback(
    async (assignmentId: string) => {
      try {
        setLoading(true);
        await EmployeeAssignmentService.deleteEmployeeAssignment(assignmentId);
        enqueueSnackbar(DELETE_RECORD_SUCCESS_MESSAGE, {
          variant: "success",
        });
        fetchEmployeeAssignments();
      } catch (error) {
        console.error(FETCH_ERROR_MESSAGE, error);
        setError(error);
        setLoading(false);
        throw error;
      }
    },
    [fetchEmployeeAssignments]
  );

  useEffect(() => {
    fetchEmployeeAssignments();
  }, [fetchEmployeeAssignments, employeeId]);

  return {
    employeeAssignments,
    loading,
    error,
    refetch: fetchEmployeeAssignments,
    addRecord: createEmployeeAssignment,
    deleteRecord: deleteEmployeeAssignment,
  };
};

export default useEmployeeAssignments;
