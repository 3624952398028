import { AutoRoleAssignmentRuleType } from "../../models";

export const translateRoleAssignmentTypeToText = (
  value: AutoRoleAssignmentRuleType["roleAssignmentType"]
): string => {
  switch (value) {
    case 1:
      return "Primary";
    case 3:
      return "Automatic";
    default:
      return "ERROR";
  }
};
