import { Add as AddIcon } from "@mui/icons-material";
import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { TEXT_FIELD_STYLES } from "../../constants";
import { useContext } from "react";
import { SimplifiedRoleAssignmentContext } from "../../context";

const GRID_SIZE_PROPS = { xs: 12, md: 6, lg: 4 };

const SimplifiedAssignmentHeader = () => {
  const {
    employees,
    loading,
    selectedEmployee,
    setSelectedEmployee,
    setShowForm,
  } = useContext(SimplifiedRoleAssignmentContext);

  const hasEmployee = !!selectedEmployee;

  return (
    <Box
      sx={{
        borderRadius: 2,
        textAlign: "left",
        marginBottom: 3,
      }}
    >
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item {...GRID_SIZE_PROPS}>
          <Autocomplete
            loading={loading}
            options={employees}
            getOptionLabel={(option) => option.fullNameWithID || ""}
            onChange={(_, value) => setSelectedEmployee(value)}
            value={
              employees.find(
                (employee) =>
                  employee.employeeID === selectedEmployee?.employeeID
              ) || null
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Employee"
                variant="outlined"
                sx={TEXT_FIELD_STYLES}
              />
            )}
          />
        </Grid>

        <Grid item {...GRID_SIZE_PROPS} sx={{ alignContent: "center" }}>
          <Button
            disabled={!hasEmployee}
            startIcon={<AddIcon />}
            onClick={() => setShowForm(true)}
          >
            Add Reach To Employee
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SimplifiedAssignmentHeader;
