import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  HelpOutline as HelpIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Props, ADGroupRuleFormErrorType } from "./interface";
import { ADGroupRuleType, RuleInactivationBehaviorType } from "../../models";
import { useEffect, useState } from "react";
import {
  BLANK_ERROR_MESSAGE,
  INACTIVATION_BEHAVIOR_HELPER_TEXT,
  TEXT_FIELD_STYLES,
} from "../../constants";
import validateADGroupRuleForm from "./utils";
import { useRegistredADGroups, useRoles } from "../../hooks";

const EMPTY_FORM_VALUE: ADGroupRuleType = {
  shortDescription: "",
  adGroup: "",
  role: "",
  active: true,
  inactivationBehavior: "Keep All Assignments" as RuleInactivationBehaviorType,
};

const AD_GROUP_HELPER_TEXT =
  "If you were not able to find the Entra Group you want, you will need to import it on the 'Import Entra Groups' page";

const GRID_SIZE_PROPS = { xs: 12, md: 6, lg: 4 };

const ADGroupRuleForm = ({ adGroupRule, onCancel, onFormSubmit }: Props) => {
  const [formFields, setFormFields] = useState<ADGroupRuleType>(
    adGroupRule || EMPTY_FORM_VALUE
  );
  const [formError, setFormError] = useState<ADGroupRuleFormErrorType>();

  const { adGroups, loading: loadingADGroups } = useRegistredADGroups();
  const { roles, loading: loadingRoles } = useRoles();

  const inEditMode = Boolean(adGroupRule);
  const Icon = inEditMode ? EditIcon : AddIcon;

  const onFormChange = (
    key: keyof ADGroupRuleType,
    value: string | boolean | number
  ) => {
    setFormFields((prev) => {
      if (!prev) return { [key]: value };
      return { ...prev, [key]: value };
    });
  };

  const handleFormSubmitClick = () => {
    const errors = validateADGroupRuleForm(formFields);
    if (Object.keys(errors).length) {
      return setFormError(errors);
    }
    onFormSubmit(formFields);
  };

  useEffect(() => {
    if (!adGroupRule) {
      return setFormFields(EMPTY_FORM_VALUE);
    }
    setFormFields(adGroupRule);
    setFormError({});
  }, [adGroupRule]);

  return (
    <Box
      sx={{
        padding: 1,
        borderRadius: 2,
        border: "1px solid lightgray",
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Icon sx={{ marginRight: 0.5 }} />
          <Typography variant="h6" component="h4">
            {inEditMode
              ? `Edit Automatic Entra Group Membership ${adGroupRule?.shortDescription}`
              : "Add Automatic Entra Group Membership"}
          </Typography>
        </Box>
        <IconButton color="error" onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item {...GRID_SIZE_PROPS}>
          <TextField
            required
            sx={TEXT_FIELD_STYLES}
            label="Short Description"
            variant="outlined"
            inputProps={{ maxLength: 25 }}
            value={formFields?.shortDescription}
            onChange={(e) => {
              onFormChange("shortDescription", e.target.value);
            }}
            error={Boolean(formError?.shortDescription)}
            helperText={formError?.shortDescription || BLANK_ERROR_MESSAGE}
          />
        </Grid>

        <Grid item {...GRID_SIZE_PROPS}>
          <Autocomplete
            disabled={inEditMode}
            loading={loadingADGroups}
            options={adGroups}
            getOptionLabel={(option) => option.groupName || ""}
            getOptionKey={(option) => option.adGroupRegistrationId || ""}
            onChange={(_, value) =>
              onFormChange(
                "adGroup",
                value?.adGroupRegistrationId?.toString() || ""
              )
            }
            value={
              adGroups.find(
                (group) =>
                  group.adGroupRegistrationId?.toString() ===
                  formFields?.adGroup
              ) || null
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Entra Group"
                variant="outlined"
                sx={TEXT_FIELD_STYLES}
                error={Boolean(formError?.adGroup)}
                helperText={formError?.adGroup || AD_GROUP_HELPER_TEXT}
              />
            )}
          />
        </Grid>

        <Grid item {...GRID_SIZE_PROPS}>
          <Autocomplete
            disabled={inEditMode}
            loading={loadingRoles}
            options={roles}
            getOptionLabel={(option) => option.roleDescriptionWithID || ""}
            onChange={(_, value) =>
              onFormChange("role", value?.roleID.toString() || "")
            }
            value={
              roles.find(
                (role) => role.roleID.toString() === formFields?.role
              ) || null
            }
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Role"
                variant="outlined"
                sx={TEXT_FIELD_STYLES}
                error={Boolean(formError?.role)}
                helperText={formError?.role || BLANK_ERROR_MESSAGE}
              />
            )}
          />
        </Grid>

        <Grid item {...GRID_SIZE_PROPS}>
          <FormControl disabled={formFields?.active}>
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <FormLabel>Inactivation Behavior</FormLabel>
              <Tooltip title={INACTIVATION_BEHAVIOR_HELPER_TEXT}>
                <HelpIcon fontSize="small" color="info" />
              </Tooltip>
            </Stack>
            <RadioGroup
              name="inactivation-behavior"
              value={formFields?.inactivationBehavior}
              onChange={(_, value) =>
                onFormChange("inactivationBehavior", value)
              }
            >
              <FormControlLabel
                value="Keep All Assignments"
                control={<Radio />}
                label="Keep All Assignments"
              />
              <FormControlLabel
                value="Deprovision Auto Assignments"
                control={<Radio />}
                label="Deprovision Auto Assignments"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item {...GRID_SIZE_PROPS}>
          <FormGroup>
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <FormLabel>Other Options</FormLabel>
            </Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Active"
              labelPlacement="end"
              checked={formFields?.active}
              onChange={(_, value) => onFormChange("active", value)}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 4,
          alignItems: "center",
        }}
      >
        <Button
          size="large"
          variant="contained"
          startIcon={<Icon />}
          onClick={handleFormSubmitClick}
        >
          {inEditMode ? `Save Changes` : "Add New Automation"}
        </Button>
      </Box>
    </Box>
  );
};

export default ADGroupRuleForm;
