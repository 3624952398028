import APIService from "../api/APIService";
import { AutomaticRoleAssignmentRuleExclusionType } from "../models";

class AutomaticRoleAssignmentRuleExclusionService {
  async getAutomaticRoleAssignmentRuleExclusions(
    ruleId: string
  ): Promise<AutomaticRoleAssignmentRuleExclusionType[]> {
    try {
      const exclusions: AutomaticRoleAssignmentRuleExclusionType[] =
        await APIService.getAutomaticRoleAssignmentRuleExclusions(ruleId);
      return exclusions;
    } catch (error) {
      console.error(
        "Error fetching automatic role assignment rule exclusions:",
        error
      );
      throw error;
    }
  }

  async createAutomaticRoleAssignmentRuleExclusion(
    ruleExclusion: AutomaticRoleAssignmentRuleExclusionType
  ): Promise<AutomaticRoleAssignmentRuleExclusionType> {
    try {
      const newEclusion: AutomaticRoleAssignmentRuleExclusionType =
        await APIService.createAutomaticRoleAssignmentRuleExclusion(
          ruleExclusion
        );
      return newEclusion;
    } catch (error) {
      console.error(
        "Error creating new automatic role assignment rules:",
        error
      );
      throw error;
    }
  }

  async deleteAutomaticRoleAssignmentRuleExclusion(
    exclusionId: string
  ): Promise<void> {
    try {
      await APIService.deleteAutomaticRoleAssignmentRuleExclusion(exclusionId);
    } catch (error) {
      console.error(
        "Error deleting automatic role assignment rule exclusion:",
        error
      );
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AutomaticRoleAssignmentRuleExclusionService();
