import { Add as AddIcon } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { AutomationRuleForm, AutomationRulesTable } from "../components";
import { ROUTES } from "../constants";
import { useAutomationRules } from "../hooks";
import { AutoRoleAssignmentRuleType } from "../models";

const { ROLE_ASSIGNMENT_RULES } = ROUTES;

const RoleAssignmentRules = () => {
  const [rule, setRule] = useState<AutoRoleAssignmentRuleType | undefined>(
    undefined
  );
  const [showForm, setShowForm] = useState(false);

  const topRef = useRef<HTMLDivElement>(null);
  const { automationRules, loading, addRecord, updateRecord } =
    useAutomationRules();

  const handleAddNewRuleClick = () => {
    setRule(undefined);
    setShowForm(true);
  };

  const handleEditRuleClick = (rule: AutoRoleAssignmentRuleType) => {
    setRule(rule);
    setShowForm(true);
    topRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const resetForm = () => {
    setShowForm(false);
    setRule(undefined);
  };

  const handleAddNewRule = async (formFields: AutoRoleAssignmentRuleType) => {
    const success = await addRecord(formFields);
    success && resetForm();
  };

  const handleEditRule = async (formFields: AutoRoleAssignmentRuleType) => {
    const success = await updateRecord(formFields);
    success && resetForm();
  };

  const handleFormSubmit = async (formFields: AutoRoleAssignmentRuleType) => {
    if (Boolean(formFields?.id)) {
      return handleEditRule(formFields);
    }
    return handleAddNewRule(formFields);
  };

  const handleActiveOrDeactiveClick = (rule: AutoRoleAssignmentRuleType) => {
    if (rule.active) {
      return updateRecord({ ...rule, active: false });
    }
    updateRecord({ ...rule, active: true });
  };

  return (
    <Box ref={topRef} sx={{ width: "100%", overflow: "auto" }}>
      <Typography variant="h3" style={{ marginBottom: "24px" }}>
        {ROLE_ASSIGNMENT_RULES.label}
      </Typography>
      <Box sx={{ display: "flex", marginBottom: 3 }}>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNewRuleClick}
        >
          Add Automatic Role Assignment
        </Button>
      </Box>
      {showForm && (
        <AutomationRuleForm
          rule={rule}
          onFormSubmit={handleFormSubmit}
          onCancel={resetForm}
        />
      )}
      <AutomationRulesTable
        loading={loading}
        automationRules={automationRules}
        onEditClick={handleEditRuleClick}
        onActiveOrDeactiveClick={handleActiveOrDeactiveClick}
      />
    </Box>
  );
};

export default RoleAssignmentRules;
