import {
  Edit as EditIcon,
  PersonRemove as PersonRemoveIcon,
} from "@mui/icons-material";
import { Box, Switch } from "@mui/material";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { useState } from "react";
import { getNameByValue } from "../../constants/organizationLevelTable";
import { Props } from "./interface";
import { translateRoleAssignmentTypeToText } from "./utils";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

const { ROLE_ASSIGNMENT_EXCLUSIONS } = ROUTES;

const AutomationRulesTable = ({
  loading,
  automationRules,
  onEditClick,
  onActiveOrDeactiveClick,
}: Props) => {
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      id: false,
      deprovisionManualAssignments: false,
      inactivationBehavior: false,
    });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        id: 1,
        field: "active",
        operator: "equals",
        value: "true",
      },
    ],
  });

  const navigateTo = useNavigate();

  const ActionsColumn: GridColDef = {
    field: "actions",
    headerName: "Actions",
    type: "actions",
    getActions: (params) => {
      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => onEditClick(params.row)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<PersonRemoveIcon />}
          label="Manage Exclusions"
          onClick={() =>
            navigateTo(
              ROLE_ASSIGNMENT_EXCLUSIONS.url.replace(":ruleId", params.row.id)
            )
          }
          color="inherit"
        />,
      ];
    },
  };

  const ActiveColumn: GridColDef = {
    field: "active",
    headerName: "Active",
    width: 120,
    renderCell: (params) => {
      return (
        <Switch
          onChange={() => {
            onActiveOrDeactiveClick(params.row);
          }}
          checked={params.value}
        />
      );
    },
  };

  const OrganizationLevelColumn: GridColDef = {
    field: "organizationLevel",
    headerName: "Organization Level",
    valueGetter: (_, value) => getNameByValue(value.organizationLevel),
  };

  const RoleAssignmentTypeColumn: GridColDef = {
    field: "roleAssignmentType",
    headerName: "Assignment Type",
    valueGetter: (_, value) =>
      translateRoleAssignmentTypeToText(value.roleAssignmentType),
  };

  const DeprovisionManualAssignmentsColumn: GridColDef = {
    field: "deprovisionManualAssignments",
    headerName: "Allow Auto-Delete",
    type: "boolean",
  };

  return (
    <Box sx={{ height: "calc(100vh - 216px)", width: "100%", marginTop: 3 }}>
      <DataGridPremium
        loading={loading}
        rowSelection={false}
        rows={automationRules || []}
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { quickFilterProps: { debounceMs: 300 } } }}
        autosizeOnMount
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
        filterModel={filterModel}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        columns={[
          ActionsColumn,
          {
            field: "id",
            headerName: "ID",
          },
          ActiveColumn,
          {
            field: "shortDescription",
            headerName: "Short Description",
            width: 160,
          },
          { field: "jobCodeId", headerName: "Job Code ID" },
          { field: "roleId", headerName: "Role ID" },
          OrganizationLevelColumn,
          RoleAssignmentTypeColumn,
          DeprovisionManualAssignmentsColumn,
          {
            field: "inactivationBehavior",
            headerName: "Inactivation Behavior",
          },
        ]}
      />
    </Box>
  );
};

export default AutomationRulesTable;
