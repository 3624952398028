import { useCallback, useEffect, useState } from "react";
import { OrgHierarchyTabularType } from "../models";
import { OrganizationalNodeService } from "../services";

const useOrgNodes = () => {
  const [orgNodes, setOrgNodes] = useState<OrgHierarchyTabularType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchOrganizationalNode = useCallback(async () => {
    try {
      setLoading(true);
      const nodes = await OrganizationalNodeService.getOrganizationalNodes();
      setOrgNodes(nodes);
    } catch (error) {
      console.error("Failed to fetch organizational node", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchOrganizationalNode();
  }, [fetchOrganizationalNode]);

  return { orgNodes, loading, error };
};

export default useOrgNodes;
