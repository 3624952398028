import APIService from "../api/APIService";
import { JobCodeType } from "../models";

class JobCodeService {
  async getJobCodes(): Promise<JobCodeType[]> {
    try {
      const jobCodes: JobCodeType[] = await APIService.getJobCodes();
      return jobCodes;
    } catch (error) {
      console.error("Error fetching job codes:", error);
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new JobCodeService();
