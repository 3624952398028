import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AutomationRuleDetailCard,
  AutomationRuleExclusionForm,
  RoleAssignmentRulesExclusionTable,
} from "../components";
import { ROUTES } from "../constants";
import { EmployeesContextProvider } from "../context";
import { useAutomationRuleDetail, useAutomationRuleExclusions } from "../hooks";
import { AutomaticRoleAssignmentRuleExclusionType } from "../models";

const { ROLE_ASSIGNMENT_EXCLUSIONS, ROLE_ASSIGNMENT_RULES } = ROUTES;

const BaseRoleAssignmentRulesExclusion = () => {
  const [showForm, setShowForm] = useState(false);

  const { ruleId } = useParams<{ ruleId: string }>();
  const navigateTo = useNavigate();

  const {
    ruleDetail,
    loading: detailLoading,
    error,
  } = useAutomationRuleDetail(ruleId || "");

  const {
    ruleExclusions,
    loading: rulesLoading,
    addRecord,
    deleteRecord,
  } = useAutomationRuleExclusions(ruleId || "");

  const handleFormSubmit = async (
    formFields: AutomaticRoleAssignmentRuleExclusionType
  ) => {
    await addRecord(formFields);
    setShowForm(false);
  };

  const handleDeleteRecordClick = async (exclusionId: string) => {
    deleteRecord(exclusionId);
  };

  useEffect(() => {
    !ruleId && navigateTo(ROLE_ASSIGNMENT_RULES.url);
  }, [navigateTo, ruleId]);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h3" style={{ marginBottom: "24px" }}>
        {ROLE_ASSIGNMENT_EXCLUSIONS.label}
      </Typography>
      <AutomationRuleDetailCard
        rule={ruleDetail}
        loading={detailLoading}
        error={error}
      />
      <Box sx={{ display: "flex", marginBottom: 3 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setShowForm(true)}
          sx={{ marginRight: 3 }}
        >
          Add Exclusion
        </Button>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigateTo(ROLE_ASSIGNMENT_RULES.url)}
        >
          Go Back To Automatic Role Assignment
        </Button>
      </Box>
      {showForm && (
        <AutomationRuleExclusionForm
          ruleId={parseInt(ruleId || "")}
          onCancel={() => setShowForm(false)}
          onFormSubmit={handleFormSubmit}
        />
      )}
      <RoleAssignmentRulesExclusionTable
        ruleExclusions={ruleExclusions}
        loading={rulesLoading}
        onDeleteRecordClick={handleDeleteRecordClick}
      />
    </Box>
  );
};

const RoleAssignmentRulesExclusion = () => {
  return (
    <EmployeesContextProvider>
      <BaseRoleAssignmentRulesExclusion />
    </EmployeesContextProvider>
  );
};

export default RoleAssignmentRulesExclusion;
